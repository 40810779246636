<template>
  <div>
    <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
      name="dashboard-child-prod-value"
    />
    <div style="overflow:hidden">
      {{ dataRow.machine_id }}
    </div>
  </div>
</template>

<script>
import Configurator from "../Configurator";
export default {
  props: {
    dataKey: {
      required: true,
    },
    dataRow: {
      required: true,
    },
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  data() {
    return {};
  },
  components: {
    Configurator,
  },
  computed: {
    configOptions() {
      return {
        test2: {
          type: "string",
        },
      };
    },
  },
};
</script>

<style>
</style>
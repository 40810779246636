<template>
  <div>
    &nbsp;
  </div>
</template>

<script>
export default {
  props: {
    dataKey: {
      required: true,
    },
    dataRow: {
      required: true,
    },
    mode:{
      default: "active", // "configuring", "previewing"
    },
    config:{

    }
  },
};
</script>

<style>
</style>
<template>
  <div class="lean">
    <div class="crosswrap" v-if="loaded">
      <div
        class="leancross"
        :class="{ nocenter: !centerSquare }"
        :style="{ width: dimensions + 'px', height: dimensions + 'px' }"
      >
        <div class="topleft">
          <button class="leanpager" @click="month--">
            <i class="fas fa-chevron-left"></i>
          </button>
        </div>
        <div class="topright">
          <button class="leanpager" @click="month++">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
        <div class="bottomleft">
          
          <p>{{ readableDate }}</p>
        </div>
        <div class="bottomright"></div>
        
        <lean-block
          :i="i"
          :month="month"
          :status="fakedData[i-1]"
          v-for="i in centerSquare ? 33 : 32"
          :key="'lean-block-' + i"
        >
        </lean-block>
      </div>
    </div>
    <div class="legend" v-if="legendEnabled">
      <h2>Legend</h2>
      <div class="color-indicator status-1">
        
      </div>
    </div>
  </div>
</template>

<script>
import LeanBlock from "./LeanBlock.vue";
export default {
  props: {
    legendEnabled: {
      default: false,
    },
    centerSquare: {
      default: false,
    },
  },
  data() {
    return {
      dimensions: null,
      loaded: false,
      month: new Date().getMonth() + 1,
      fakedData: null,
    };
  },
  mounted() {
    this.sizeCross();
    this.loadData();
    window.addEventListener("resize", this.sizeCross);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.sizeCross);
  },
  methods: {
    sizeCross() {
      let h = this.$el.clientHeight;
      let w = this.$el.clientWidth - (this.legendEnabled ? 150 : 0);
      this.dimensions = Math.min(w, h);
    },
    loadData() {
      this.loaded = false;
      let faked = [];
      for (let i = 1; i <= this.daysInMonth; i++) {
        faked.push(Math.floor(Math.random() * 5));
      }
      this.fakedData = faked;
      this.loaded = true;
    },
  },
  computed: {
    daysInMonth() {
      return new Date(new Date().getFullYear(), this.month, 0).getDate();
    },
    readableDate() {
      let date = new Date(new Date().getFullYear(), this.month - 1, 1);
      return date.getFullYear() + ":" + (date.getMonth() + 1);
    },
  },
  watch: {
    month() {
      this.loadData();
    },
  },
  components: {
    LeanBlock,
  },
};
</script>

<style lang="scss">
$blocksize: 40px;
$borderwidth: 1px;
.lean {
  .leanpager{
    background:white;
    width:100%;
    border:none;
  }
  .crosswrap {
    flex-direction: column;
    justify-content: center;
    .leancross {
      margin: 0 auto;
      //background:black;
      display: grid;
      flex: 0 0 auto;
      grid-gap: 0.1rem;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(7, 1fr);
      grid-template-areas:
        "top-left top-left block-1 block-2 block-3 top-right top-right"
        "top-left top-left block-4 block-5 block-6 top-right top-right"
        "block-7 block-8 block-9 block-10 block-11 block-12 block-13"
        "block-14 block-15 block-16 block-17 block-18 block-19 block-20"
        "block-21 block-22 block-23 block-24 block-25 block-26 block-27"
        "bottom-left bottom-left block-28 block-29 block-30 bottom-right bottom-right"
        "bottom-left bottom-left block-31 block-32 block-33 bottom-right bottom-right";
      &.nocenter {
        grid-template-areas:
          "top-left top-left block-1 block-2 block-3 top-right top-right"
          "top-left top-left block-4 block-5 block-6 top-right top-right"
          "block-7 block-8 block-9 block-10 block-11 block-12 block-13"
          "block-14 block-15 block-16 block-x block-17 block-18 block-19"
          "block-20 block-21 block-22 block-23 block-24 block-25 block-26"
          "bottom-left bottom-left block-27 block-28 block-29 bottom-right bottom-right"
          "bottom-left bottom-left block-30 block-31 block-32 bottom-right bottom-right";
      }
      .block {
        background: blue;
        color: white;
        border:1px solid black;
        //text-align: center;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        //outline:1px solid black;
        text {
          stroke: white;
          fill: white;
        }
        &.status-0 {
          background:gray;
        }
        &.status-1 {
          background:green;
        }
        &.status-2 {
          background:yellow; text {
            stroke: black;
            fill: black;
          }
        }
        &.status-3 {
          background:orange;
        }
        &.status-4 {
          background:red;
        }

        &.future {
          background: white;
          color: black;
          cursor: not-allowed;
          text {
            stroke: black;
            fill: black;
          }
        }
      }
      
      .block-x{
        grid-area:block-x;
      }
      .topleft {
        grid-area: top-left;
      }
      .topright {
        grid-area: top-right;
      }
      .bottomleft {
        grid-area: bottom-left;
        background:white;
      }
      .bottomright {
        grid-area: bottom-right;
        background:white;
      }
    }
  }

  .legend {
    width: 150px;
  }
}
</style>
<template>
  <div
    class="dashboardtable"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{}"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-production-goal"
      :options="configOptions"
      :config="config"
    />
    <div
      class="left-aligned left-align align-left dashboard-widget"
      v-if="mode !== 'configuring'"
    >
      <c-table
        v-if="loaded && template && mountedFully"
        :data="currentQueryData"
        :column-config="templateConfig"
        style="height: 100%"
        primary-key="autoId"
        :config-string="template"
        default-sort-dir="asc"
        :teleport="false"
        :key="template"
        :overview-id="config.ownOverviewSource?.record_no"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import OrderSummaryShared from "@/components/shared/OrderSummary.vue";
import store from "@/store";
import axios from "@/requests/axios";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import { execute_json } from "@/helpers/requests";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      pulse: null,
      configOptions: {
        ...stylingConfiguration,
        ...alignConfiguration,
        ownOverviewSource: {
          type: "list",
          source: "ownOverviewList",
        },
        interval: {
          type: "list",
          options: [5,10,20, 60, 120, 180, 300, 600, 900],
          default: 180,
        },
      },
      mountedFully: false,
    };
  },
  mounted() {
    let promises = [];
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "web_overviews" })
    );
    promises.push(
      store.dispatch("config/loadBaseConfig", { base_code: "webcolumns" })
    );
    if (this.currentQueryId) {
      promises.push(
        store.dispatch("queries/loadQueryData", {
          record_no: this.currentQueryId,
          parameters: this.baseConfig?.queryParameters,
        })
      );
      this.pulse = new Pulse([
        {
          action: "queries/loadQueryData",
          parameters: {
            record_no: this.currentQueryId,
            parameters: this.baseConfig?.queryParameters,
          },
          primaryKey: "record_no",
          interval: +this.config?.interval || 180,
        },
      ]);
    } else {
      console.log(this.config.ownOverviewSource);
    }
    Promise.all(promises).then(() => {
      this.loaded = true;
      this.$nextTick(() => {
        this.mountedFully = true;
      });
    });
  },
  beforeUnmount() {},
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      queryData: (state) => state.queries.data,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      columnConfigFromTemplateName: "config/columnConfigByTemplate",
    }),
    template() {
      return this.config.ownOverviewSource?.record_no
        ? "web_own_overview_" + this.config.ownOverviewSource.record_no
        : null;
    },
    currentQueryId() {
      return this.config.ownOverviewSource?.record_no
        ? this.config.ownOverviewSource.sql_query_id
        : null;
    },
    baseConfig() {
      return this.columnConfigFromTemplateName(this.configTemplate);
    },
    configTemplate() {
      return this.template + "_baseconfig";
    },
    templateConfig() {
      return this.columnConfigFromTemplateName(this.template);
    },
    currentQueryData() {
      let i = 0;
      let baseId = this.currentQueryId;
      return (
        this.queryData[this.currentQueryId]?.map((d) => {
          i++;
          return {
            ...d,
            autoId: baseId + "-" + (''+i).padStart(6,'0'),
          };
        }) || []
      );
    },
  },
  watch: {
    currentQueryId() {
      if (this.currentQueryId) {
        store.dispatch("queries/loadQueryData", {
          record_no: this.currentQueryId,
          parameters: this.baseConfig?.queryParameters,
        });
      } else {
        console.log(this.config.ownOverviewSource);
      }
    },
  },
  components: {
    Configurator,
    OrderSummaryShared,
  },
};
</script>

<style>
</style>
<template>
  <div
    class="background-section"
    :style="styleObj"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-section"
      :options="configOptions"
      :config="config"
    />
  </div>
</template>

<script>
import Configurator from "./Configurator";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      configOptions: {
        ...stylingConfiguration,
        backgroundUrl:{
          type:"text"
        },
        backgroundSize:{
          type:"list",
          options:[
            "cover","contain","auto"
          ],
          default:"contain"
        },
        backgroundRepeat:{
          type:"list",
          options:[
            "no-repeat","repeat"
          ],
          default:"no-repeat"
        },
        backgroundPosX:{
          type:"list",
          options:[
            "left","right","center"
          ],
          default:"center"
        },
        backgroundPosY:{
          type:"list",
          options:[
            "top","center","bottom"
          ],
          default:"center"
        }
      },
    };
  },
  mounted() {},
  methods: {},
  computed: {
    styleObj(){
      if (this.mode === 'configuring'){
        return {};
      }
      return { 
        backgroundColor: this.config.backgroundEnabled
          ? this.config.backgroundColor
          : 'transparent', 
        color: this.config.color,
        backgroundImage:"url("+this.config.backgroundUrl+")",
        backgroundSize:this.config.backgroundSize,
        backgroundRepeat:this.config.backgroundRepeat,
        backgroundPosition:this.config.backgroundPosX +" "+this.config.backgroundPosY,
        }
    }
  },
  watch: {},
  components: {
    Configurator,
  },
};
</script>

<style>
</style>
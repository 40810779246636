<template>
  <div
    class="grid-component"
    :class="{
      background: widget.component === 'dashboard-section',
      [widget.config.style]: widget.config.style,
    }"
    :style="styleObj"
  >
    <div class="inner-grid-component">
      <component :is="widget.component" :config="widget.config" :key="'db-component-'+widget.id" />
    </div>
  </div>
</template>

<script>
import DashboardGraph from "@/components/dashboards/DashboardGraph.vue";
import DashboardGraphSql from "@/components/dashboards/DashboardGraphSql.vue";
import DashboardGraphLocation from "@/components/dashboards/DashboardGraphLocation.vue";
import DashboardSection from "@/components/dashboards/DashboardSection.vue";
import DashboardGauge from "@/components/dashboards/DashboardGauge.vue";
import DashboardSqlGauge from "@/components/dashboards/DashboardSqlGauge.vue";
import DashboardStatus from "@/components/dashboards/DashboardStatus.vue";
import ProductionValue from "@/components/dashboards/ProductionValue.vue";
import DashboardParent from "@/components/dashboards/DashboardParent.vue";
import DashboardLean from "@/components/dashboards/DashboardLean.vue";
import DashboardTimeHistory from "@/components/dashboards/DashboardTimeHistory.vue";
import DashboardProductionGoal from "@/components/dashboards/DashboardProductionGoal.vue";
import DashboardOrderSummary from "@/components/dashboards/DashboardOrderSummary.vue";
import DashboardTable from "@/components/dashboards/DashboardTable.vue";
import FreeText from "@/components/dashboards/FreeText";
import SpecialDashboardComponent from "@/components/dashboards/SpecialDashboardComponent";

export default {
  props: {
    widget: {
      required: true,
    },
    config: {
      required: true,
    },
  },
  components: {
    DashboardSection,
    DashboardGraph,
    DashboardGraphSql,
    DashboardGraphLocation,
    DashboardLean,
    ProductionValue,
    DashboardParent,
    FreeText,
    DashboardGauge,
    DashboardSqlGauge,
    DashboardStatus,
    DashboardTimeHistory,
    DashboardProductionGoal,
    DashboardOrderSummary,
    SpecialDashboardComponent,
    DashboardTable,
  },
  computed: {
    styleObj() {
      return {
        left: this.widget.pos.x * (100 / this.config.size.x) + "%",
        top: this.widget.pos.y * (100 / this.config.size.y) + "%",
        width: this.widget.pos.w * (100 / this.config.size.x) + "%",
        height: this.widget.pos.h * (100 / this.config.size.y) + "%",
      };
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="dashboardparent"
    :style="{}"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
      name="dashboard-parent"
    />
    <component
      v-if="mode == 'configuring' && config.childComponent"
      :is="config.childComponent"
      :mode="mode"
      :config="config.child"
      :dataRow="{}"
      :dataKey="''"
    />
    <div
      v-if="mode !== 'configuring' && config.childComponent && config.renderMode == 'stack'"
      class="childwrap" 
    >
      <div class="stack" v-for="(stack, i) in stacks" :key="i">
        <component
          class="child-component"
          :is="config.childComponent"
          :key="rowIndex + '-' + row[config.valueKey]"
          v-for="(row,rowIndex) in stack"
          :style="{ flex: row[config.stackvalueKey] / stackSize(stack) }"
          :dataRow="row"
          :dataKey="config.valueKey"
          :config="config.child"
          :mode="mode"
          :class="{ preview: mode == 'preview' }"
        />
      </div>
    </div>
     <div
      v-if="mode !== 'configuring' && config.childComponent && config.renderMode == 'group-by'"
      class="childwrap" 
    >
      <div class="group" v-for="(group, i) in stacks" :key="i">
        <component
          class="child-component"
          :is="row.renderType == 'spacer' ? 'spacer' : row.renderType == 'header' ? config.headerComponent : config.childComponent"
          :key="rowIndex + '-' + row[config.valueKey]"
          v-for="(row,rowIndex) in group"
          :dataRow="row"
          :dataKey="config.valueKey"
          :config="config.child"
          :mode="mode"
          :class="{ preview: mode == 'preview' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { mapGetters, mapState } from "vuex";
import ProductionValue from "./Children/ProdValue.vue";
import TestComponent from "./Children/TestComponent.vue";
import Spacer from "./Children/Spacer.vue";
import ZoneOverview from "./Children/ZoneOverview.vue";

import {
  alignConfiguration,
  stylingConfiguration,
  sourceConfiguration,
  sourceComputed,
  sourceState,
  sourceGetters,
  sourceWatchers,
} from "@/helpers/configurator";
import { hoursToHhMm, secondsToHhMm } from "@/helpers/dates";
import { Pulse } from "@/helpers/heartbeat";
import _ from "lodash";
import store from "@/store";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      pulse: null,
      cid: _.uniqueId("db"),
      multiSource: true,
    };
  },
  mounted() {
    let dependant = [];
    if (this.config.child === undefined) {
      this.config.child = {};
    }
    if (this.config.machineId) {
      dependant.push({
        action: "orders/loadPlannedOrdersForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
      dependant.push({
        action: "orders/loadRunningOrderForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
    }
    if (this.sqlSource) {
      dependant.push({
        action: "queries/loadQueryData",
        interval: 20,
        parameters: {
          record_no: this.sqlSource,
          parameters: this.parameters,
          record_plus: this.sqlSource + ":" + this.config.machineId,
        },
        primaryKey: "record_plus",
      });
    }
    this.pulse = new Pulse([
      {
        action: "machines/loadMachines",
        interval: 5,
      },
      ...dependant,
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  name: "dashboard-parent",
  methods: {
    groupify(n,source){
      let groups = _.groupBy(source,this.config.groupvalueKey);
      let groupsArray = [];
      let groupIndex = 0;
      _.each(groups,(g,key) => {
        let group = [
          {'renderType':'header', group:key},
          ...g.map(g => { return {...g,renderType:'child'}})
        ];
        if (this.config.spaceBeforeGroup && groupIndex != 0){
          group.unshift({'renderType':'spacer'});
        }
        if (this.config.spaceAfterGroup && groupIndex < Object.keys(groups).length -1 ){
          group.push({'renderType':'spacer'});
        }
        groupIndex++;
        groupsArray.push(group);
      })
      if (this.config.groupBreakLine){
        let total = source.length + groupsArray.length * ( 1+ this.config.spaceBeforeGroup + this.config.spaceAfterGroup) -this.config.spaceBeforeGroup - this.config.spaceAfterGroup;
        let groupSize = Math.ceil(total/n);
        let output = [];
        let currentStack = [];
        for (let i = 0;i < groupsArray.length;i++){
          for (let j = 0;j < groupsArray[i].length ; j++){
            if (currentStack.length >= groupSize){
              output.push(currentStack);
              currentStack = [];
            }
            currentStack.push(groupsArray[i][j]);
          }
        }
        if (currentStack.length){
          output.push(currentStack);
        }
        return output;
      }else{
        return this.stackify(n,groupsArray);
      }
    },
    stackify(n, stacks) {
      let m = 3; //this.maxFromStacks(stacks);
      let copy = _.cloneDeep(stacks); // copy
      let j = 0; // infinite loop protection;
      while (copy.length > n && n >= 1 && j < 500) {
        console.log(copy, n);
        let l = copy.length;
        //loop through copy, add stacks together if possible, increase m
        for (let i = 1; i < copy.length; i++) {
          let stack = copy[i];
          let prevStack = copy[i - 1];
          if (this.stackSize(stack) + this.stackSize(prevStack) <= m) {
            copy[i - 1] = [...prevStack, ...stack];
            copy.splice(i, 1);
            break;
          }
        }
        if (copy.length == l) {
          m++;
        }
        j++;
      }
      console.log(m, copy);
      return copy;
    },
    maxFromStacks(stacks) {
      let summedStacks = stacks.map((s) => {
        return this.stackSize(s);
      });
      let max = summedStacks.reduce((a, b) => {
        return Math.max(a, b);
      }, 0);
      return max;
    },
    stackSize(stack) {
      return (
        stack.reduce((a, b) => {
          return a + (b[this.config?.stackvalueKey] || 0);
        }, 0) || 0
      );
    },
  },
  computed: {
    ...mapState({
      ...sourceState,
    }),
    ...mapGetters({
      ...sourceGetters,
    }),
    ...sourceComputed,
    configOptions() {
      let vm = this;
      let configOptions = {
        ...stylingConfiguration,
        ...alignConfiguration,
        ...sourceConfiguration(this.sqlQueryPlug),
        renderMode: {
          type: "list",
          options: ["group-by", "stacks"],
          default: "group-by",
        },
      };
      if (this.config.renderMode == "group-by") {
        configOptions.columns = {
          type: "number",
          default: 0,
        };
        configOptions.groupvalueKey = {
          type: "list",
          source: "sourceKeys",
        }; 
        configOptions.groupBreakLine = {
          type: "boolean",
          default:false
        };
         configOptions.spaceBeforeGroup = {
          type: "boolean",
          default:false
        };
         configOptions.spaceAfterGroup = {
          type: "boolean",
          default:false
        };
        configOptions.headerComponent = {
          type: "list",
          options: ["header-component","production-value"],
        };
      }
      if (this.config.renderMode == "stacks") {
        configOptions.stacks = {
          type: "number",
          default: 0,
        };
        configOptions.stackvalueKey = {
          type: "list",
          source: "sourceKeys",
        };
      }
      configOptions.childComponent = {
        type: "list",
        options: ["production-value", "test-component", "zone-overview"],
      };

      return configOptions;
    },
    stacks() {
      let stacks = [];
       if (
        this.config.renderMode == "group-by"
      ) {
        //return this.source.map((r) => [r]);
        return this.groupify(
          this.config.columns,
          this.source
        );
      }
      if (
        this.config.renderMode == "stacks" &&
        this.config.stacks > 0 &&
        this.config.stacks < this.source.length
      ) {
        //return this.source.map((r) => [r]);
        return this.stackify(
          this.config.stacks,
          this.source.map((r) => [r])
        );
      }
      return this.source.map((r) => [r]);
    },
  },
  watch: {
    $route() {
      this.pulse.stop();
      let dependant = [];
      if (this.config.machineId) {
        dependant.push({
          action: "orders/loadPlannedOrdersForMachine",
          interval: 60,
          parameters: { machine_id: this.config.machineId },
          primaryKey: "machine_id",
        });
        dependant.push({
          action: "orders/loadRunningOrderForMachine",
          interval: 60,
          parameters: { machine_id: this.config.machineId },
          primaryKey: "machine_id",
        });
      }
      if (this.sqlSource) {
        store.dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: this.parameters,
        });
      }
      this.pulse = new Pulse([
        {
          action: "machines/loadMachines",
          interval: 5,
        },
        ...dependant,
      ]);
    },
    ...sourceWatchers,
  },
  components: {
    Configurator,
    ProductionValue,
    TestComponent,
    ZoneOverview,
    Spacer,
  },
};
</script>

<style lang="scss">
.dashboardparent {
  .childwrap {
    flex-direction: row;
    flex-wrap: wrap;
    .child-component,
    .child-component > div {
      flex-direction: column;
    }
  }
  .stack, .group {
    flex-direction: column;
    > .child-component {
      flex: 1;
    }
  }
  .preview {
    border: 1px dashed red;
  }
  .full-lh {
    line-height: 100%;
    font-size: 1rem;
  }
}
</style>
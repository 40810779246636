<template>
  <div
    class="textwrap"
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
      transform:
        mode !== 'configuring' && config.rotation != 0
          ? 'rotate(' + config.rotation + 'deg)'
          : null,
      'white-space': mode !== 'configuring' && config.rotation != 0 ? 'nowrap' : null,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
    />

    <v-html
      v-if="mode === 'preview'"
      :tag="config.htmlTag || 'h2'"
      :text="text || 'preview text'"
      :fontSize="config.fontSize"
    />
    <v-html
      v-if="mode === 'active' && config.htmlTag !== 'fit'"
      :tag="config.htmlTag"
      :text="text"
      :fontSize="config.fontSize"
      :color="config.color"
    />
    <svg
      v-if="mode === 'active' && config.htmlTag == 'fit'"
      class="svg-text-fit"
      viewBox="0 0 56 18"
    >
      <text :fill="config.color" x="50%" y="100%" text-anchor="middle">
        {{ text }}
      </text>
    </svg>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      loadingFor: null,
      loadedData: null,
    };
  },
  mounted() {
    this.loadData();
    this.startInterval();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      console.warn("loading data...");
      if (this.config.useSqlQuery) {
        if (
          this.config.sqlSource &&
          this.loadingFor != this.config.sqlSource &&
          this.paramsSet
        ) {
          this.fetchData();
        } else {
          if (!this.loaded) {
            store.dispatch("queries/loadQueryList");
            this.loaded = true;
          }
          setTimeout(this.loadData, 500);
        }
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (this.cInterval && this.cInterval > 5) {
        console.warn("enabling interval fetching");
        this.interval = setInterval(this.intervalFetch, this.cInterval * 1000);
      }
    },
    intervalFetch() {
      if (this.loadingFor === null && this.config.sqlSource && this.paramsSet) {
        this.fetchData();
      }
    },
    fetchData() {
      this.loadingFor = this.config.sqlSource;
      store
        .dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: this.parameters,
        })
        .then((data) => {
          this.loadingFor = null;
          this.loadedData = data;
        });
    },
  },
  computed: {
    ...mapGetters({
      queryByRecordNo: "queries/byRecordNo",
    }),
    configOptions() {
      return {
        ...stylingConfiguration,
        ...alignConfiguration,
        htmlTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit", "marquee"],
          default: "h1",
        },
        fontSize: {
          type: "list",
          options: [
            -1, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60, 70, 80,
            90, 100, 110, 120,
          ],
          default: -1,
        },
        rotation: {
          type: "list",
          options: [0, 90, -90],
          default: 0,
        },
        useSqlQuery: {
          type: "boolean",
          default: false,
        },
        ...this.textOptions,
      };
    },
    textOptions() {
      if (this.config.useSqlQuery) {
        return {
          sqlSource: {
            type: "list",
            source: "sqlList",
          },
          ...this.parameterConfigOptions,
          textKey: {
            type: "list",
            source: "sqlKeys",
          },
          interval: {
            type: "list",
            options: [20, 60, 120],
          },
        };
      } else {
        return {
          text: {
            type: "text",
          },
        };
      }
    },
    text() {
      return this.config.useSqlQuery
        ? this.loadedData?.map((d) => d[this.config.textKey]).join(" - ")
        : this.config.text;
    },
    activeQuery() {
      if (this.config.sqlSource) {
        return this.queryByRecordNo(this.config.sqlSource);
      }
      return null;
    },
    cInterval() {
      return this.config.interval;
    },
    sqlParametersFromString() {
      let sqlParameters = [];
      if (this.activeQuery) {
        const regex = /(<%.*?%>)/g;
        sqlParameters = this.activeQuery.sql_query
          .match(regex)
          .filter((m) => m !== "<%company_id%>");
      }
      return sqlParameters;
    },
    parameterConfigOptions() {
      let extras = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        extras["param_" + i] = {
          label: this.sqlParametersFromString[i],
          type: "string",
        };
      }
      return extras;
    },
    parameters() {
      let params = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        params[this.sqlParametersFromString[i]] = this.config["param_" + i];
      }
      return params;
    },
    paramsSet() {
      if (!this.activeQuery) {
        return false;
      }
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        if (
          this.config["param_" + i] == null ||
          this.config["param_" + i] == ""
        ) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    sqlSource() {
      this.loadData();
    },
  },
  components: {
    Configurator,
  },
};
</script>

<style>
</style>
<template>
  <div
    class="productiongoalwrap"
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-production-goal"
      :options="configOptions"
      :config="config"
    />
    <div class="left-align" v-if="mode !== 'configuring' && goal?.company_id" :style="{padding:config.padding || null}">
       <v-html
             class="present-value bold"
             :tag="config.htmlTagHeader || 'h2'"
             :color="config.color"
             :text="config.customHeader ? config.customHeader : $t('6660')+' - '+currentMachineLabels[config.location]"
          />
     
      <div v-if="goal !== null" class="row" style="flex:0;">
        <div class="col-12" v-if="config.showType">
          <v-html
             class="present-value bold"
             :tag="config.htmlTagLabels || 'p'"
             :color="config.color"
             :text="goal.production_type"
          />
        </div>
        <br>
        <div class="col-8" style="flex-direction:column;">
           <v-html
             class="label-value"
             :tag="config.htmlTagLabels || 'p'"
             :color="config.color"
             :text="$t('4722')+'/'+$t('729')"
          />
          <v-html
             class="present-value bold"
             :tag="config.htmlTagValues || 'p'"
             :color="config.color"
             :text="currentResultValue.toFixed(0)+'/'+currentTargetValue.toFixed(0)"
          />
        </div>
        <div class="col-4" style="flex-direction:column;">
           <v-html
             class="label-value"
             :tag="config.htmlTagLabels || 'p'"
             :color="config.color"
             :text="$t('4543')"
          />
          <v-html
             class="present-value bold"
             :tag="config.htmlTagValues || 'p'"
             :color="config.color"
             :text="( currentDiffValue > 0 ? '+':'')+currentDiffValue.toFixed(0)"
          />
        </div>
        
        <div class="col-12" :style="{'max-height':config.maxArrowHeight || 'none'}">
          <svg-arrow :colors="currentDrawingValue" preserveAspectRatio="none"></svg-arrow>
        </div>
      </div>
      <div v-else>
        {{ $t("6661") }}
      </div>
    </div>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import SvgArrow from "@/components/svg-arrow";
import axios from "@/requests/axios";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import { execute_json } from "@/helpers/requests";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,

      pulse: null,
      targetMap: {
        target_good_qty: "result_good_qty",
        target_good_qty_alt_unit: "result_good_qty_alt_unit",
        target_earned_hours: "result_earned_hours",
        //target_scheduled_hours: "result_consumed_scheduled_hours",
      },
      diffMap: {
        target_good_qty: "diff_good_qty",
        target_good_qty_alt_unit: "diff_qty_alt_unit",
        target_earned_hours: "diff_earned_hours",
        //target_scheduled_hours: "diff_earned_hours",
      },
      configOptions: {
        ...stylingConfiguration,
        ...alignConfiguration,
        machineId: {
          type: "list",
          source: "machineList",
        },
        location: {
          type: "list",
          options: [
            "machine_id", //
            "department_id", //
            "zone_id", //
            "category_id", //
          ],
          default: "machine_id",
        },
        target: {
          type: "list",
          options: [
            "target_good_qty",
            "target_good_qty_alt_unit",
            "target_earned_hours",
          ],
          default: "target_good_qty",
        },
        showType:{
          type:"boolean",
          default:true,
        },customHeader: {
          type: "text",
          default: null,
        },
        production_type: {
          type: "text",
          default: null,
        },
         htmlTagHeader: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "h2",
        },
         htmlTagValues: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "p",
        },
         htmlTagLabels: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "p",
        },
        maxArrowHeight:{
          type:"list",
          options:["none","1em","2em","3em","4em","5em","6em","7em","8em","9em","10em","11em","12em","13em","14em"],
          default:"none"
        },
        padding:{
          type:"list",
          options:['0px','10px','20px','40px'],
          default:'20px'
        }
      },
    };
  },
   mounted() {
    this.pulse = new Pulse([
      {
        action: "goals/loadProductionGoals",
        interval: 60,
      },
      {
        action: "machines/loadMachine",
        interval: 60,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
      specificGoals: "goals/specificGoals",
    }),
    machine_id() {
      return this.config.machineId || this.machine.machine_id;
    },
    goals() {
      return this.specificGoals(
        this.config.location,
        this.currentMachineValues,
        this.config.target,
        this.config.production_type || null
      );
    },
    goal() {
      return this.goals[this.config.showIndex] || this.goals[0] || null;
    },
    machine() {
      return this.config.machineId
        ? this.machineById(this.config.machineId)
        : this.machines[0] || {};
    },

    currentDrawingValue() {
      return [
        {
          color: "green",
          per:
            (this.currentResultValue -
              (this.currentDiffValue > 0 ? this.currentDiffValue : 0)) /
            this.currentTargetValue,
        },
        {
          color: this.currentDiffValue < 0 ? "red" : "lightgreen",
          per: Math.abs(this.currentDiffValue / this.currentTargetValue),
        },
        {
          color: "gray",
          per: 1,
        },
      ];
    },
    currentResultValue() {
      return this.goal?.[this.targetMap[this.config.target]] || 0;
    },
    currentDiffValue() {
      return this.goal?.[this.diffMap[this.config.target]] || 0;
    },
    currentTargetValue() {
      return this.goal?.[this.config.target];
    },
    currentMachineValues() {
      return {
        machine_id: this.machine_id,
        category_id: this.machine.category,
        zone_id: this.machine.zone,
        department_id: this.machine.department,
      };
    },
    currentMachineLabels() {
      return {
        machine_id: this.machine.object_description,
        category_id: this.machine.cat_desc,
        zone_id: this.machine.zone_desc,
        department_id: this.machine.department_description,
      };
    },
  },
  watch: {},
  components: {
    Configurator,
    SvgArrow
  },
};
</script>

<style>
</style>
<template>
  <div
    class="graphwrap"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
      marginBottom: '40px',
    }"
  >
    <div v-if="mode === 'preview'">
      <h2>No preview available during configuration</h2>
    </div>
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-time-history"
      :options="configOptions"
      :config="config"
      @configuring="configuring"
    />
    <time-history-wrap
      v-if="mode === 'active'"
      :start="startDate2"
      :startTime="startTime2"
      :end="endDate2"
    >
      <div class="inner-history-wrap">
        <time-history
          v-for="(chunk, key) in keyedData"
          :key="startTime2+'-'+key"
          :timeslots="chunk"
          :index="indexes[key]"
          @addSelect="toggleSelect"
        />
      </div>
    </time-history-wrap>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { getNow, getNowDate } from "@/helpers/dates";
import axios from "@/requests/axios";
import store from "@/store";
import { flattenMultiRow } from "@/requests/requestParcer";
import TimeHistory from "@/components/TimeHistory";
import TimeHistoryWrap from "@/components/TimeHistoryWrap";
import Configurator from "./Configurator.vue";
import {
  stylingConfiguration,
  updateConfiguration,
} from "@/helpers/configurator";

export default {
  props: {
    mode: {
      default: "active",
    },
    config: {},
  },
  emits: ["configuring"],
  data() {
    return {
      data: [],
      selected: [],
      loading: false,
      loaded:false,
      loadInterval:null,
      startTime2:this.startTime(),
      endTime2:this.endTime(),
      endDate2:this.endDate(),
      startDate2:this.startDate(),
      intervalJitter:0,
      initialLoad: false,
      configOptions: {
        ...stylingConfiguration,
        ...updateConfiguration,
        period: {
          type: "list",
          options: ["shift","today", "h8", "h24", "h48"],
        },
        locationtype: {
          type: "list",
          required: true,
          source: "locationtypeList",
        },
        location: {
          type: "list",
          required: true,
          source: "locationList",
        },
        machineList: {
          type: "text",
          required: false,
          placeholder: "Colon seperated machine_ids",
          default: null,
        },
      },
    };
  },
  mounted() {
    if (this.mode == "active") {
      this.updateStartTime();
      this.load();
      if (this.config.interval !== null) {
      let load = this.load;
      let updateStartTime = this.updateStartTime;
      console.log("adding interval...");
      this.loadInterval = setInterval(function () {
        updateStartTime();
        load();
      }, (this.config.interval + this.intervalJitter) * 1000);
    }
    }
    //TODO: interval
  },
  beforeUnmount() {
    if (this.updateInterval) {
      console.log("clearing interval...");
      clearInterval(this.loadInterval);
    }
  },
  methods: {
    configuring(config) {
      this.$emit("configuring", config);
    },
    updateStartTime(){
        this.startTime2 = this.startTime();
        this.endTime2 = this.endTime();
        this.startDate2 = this.startDate();
        this.endDate2 = this.endDate();
    },
    load() {
      this.loading = true;
      this.selected = [];
      axios
        .post("balthzar_get_graphical_history_data_for_machines", {
          axiosflag: "multiset",
          machine_string: this.machineString,
          strStart_time: this.startTime2,
          strEnd_time: this.endTime2,
        })
        .then(({ data }) => {
          console.log(data);
          //delete data[0];
          this.data = flattenMultiRow(data)
            .filter((row) => row.length)
            .map((row) => flattenMultiRow(row))
            .sort((a, b) => a[0].machine_id.localeCompare(b[0].machine_id));
          this.loading = false;
          this.loaded = true;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    toggleSelect(stop) {
      if (this.selected.findIndex((s) => s.stop === stop.stop) > -1) {
        this.selected = this.selected.filter((s) => s.stop !== stop.stop);
      } else {
        this.selected.push(stop);
      }
      let selectedRows = _.cloneDeep(this.selected);
      if (this.selected.length > 0 && this.selected[0]) {
        store.commit("bottomnav/enable");
        store.commit("bottomnav/setType", "uncoded-multiple");
        store.commit("bottomnav/setData", selectedRows);
        let i = selectedRows.length - 1;
        store.dispatch("stopcodes/loadStopcodesForMachine", {
          machine_id: selectedRows[i].machine_id,
          reloadCachedValues: false,
        });
        store.dispatch("machines/loadTouchConfigForMachine", {
          machine_id: selectedRows[i].machine_id,
          reloadCachedValues: false,
        });
      } else {
        store.commit("bottomnav/disable");
        store.commit("bottomnav/setData", []);
      }
    },
    startTime() {
      if (this.period === "shift"){
        return this.startDate().toBalthzarTime();
      }
      if (this.period === "h8") {
        let d = new Date();
        d.setHours(d.getHours() - 8);
        return d.toBalthzarTime();
      }
      if (this.period === "h24") {
        return getNow(-1);
      }
      if (this.period === "h48") {
        return getNow(-2);
      }
      if (this.period === "today") {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        return d.toBalthzarTime();
      }
      return getNow(-1);
    },
    endTime() {
      return getNow();
    },
    startDate() {
      if (this.period === "shift"){
        let d = this.machineListFiltered?.[0]?.current_shift_start_time?.replace(" ","T") ? new Date(this.machineListFiltered?.[0]?.current_shift_start_time?.replace(" ","T")) : getNowDate(-1);
        return d;
      }
      if (this.period === "h8") {
        let d = new Date();
        d.setHours(d.getHours() - 8);
        return d;
      }
      if (this.period === "h24") {
        return getNowDate(-1);
      }
      if (this.period === "h48") {
        return getNowDate(-2);
      }
      if (this.period === "today") {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        return d;
      }
      return getNowDate(-1);
    },
    endDate() {
      return getNowDate();
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    loctype() {
      return this.config.locationtype || "department";
    },
    loc() {
      return this.config.location;
    },
    period() {
      return this.config.period || "today";
    },
    indexes() {
      let indexes = {};
      let i = 0;
      for (var propertyName in this.keyedData) {
        indexes[propertyName] = i;
        i++;
      }
      return indexes;
    },
    
    groupedMachines() {
      /* TODO: config value
      if (this.$route.path.includes("/mine/")) {
        let config = this.config;
        //console.warn("second filter triggered");
        return this.machines.filter((m) => {
          //return true;
          return config.myMachines.includes(m.machine_id);
        });
      }
      */

      /* TODO: config value
      if (
        this.$route.path.includes("/actives/") ||
        this.$route.path.includes("/a-actives/")
      ) {
        //console.warn("first filter triggered");
        return _.filter(this.machines, (m) => {
          return m.prod_time && +m.current_shift_code > 0 && m.p_order != "0";
        });
      }
      */

      return this.machines;
    },
    filteredMachines() {
      //return this.groupedMachines;
      let locationtype = this.loctype;
      let location = this.loc;
      if (locationtype === "undefined" || locationtype === "mine") {
        return this.groupedMachines;
      }
      return _.filter(this.groupedMachines, (m) => {
        return m[locationtype] == location;
      });
    },
    machineListFiltered() {
      if (this.config.machineList && this.config.machineList.length) {
        let listed = this.config.machineList.split(";");
        console.warn(listed);
        if (listed && listed.length) {
          return this.machines.filter((m) =>
            listed.includes(m.machine_id)
          );
        }
      }
      return this.filteredMachines;
    },
    machineString() {
      return this.machineListFiltered
        .map((m) => m.machine_id)
        .sort()
        .join(";");
    },
    keyedData() {
      if (this.loaded && this.data.length) {
        let machine_ids = this.machineListFiltered.map((m) => m.machine_id_real || m.machine_id).sort();
        let keyedData = {};
        for (let i = 0; i < machine_ids.length; i++) {
          let id = machine_ids[i];
          keyedData[id] = [{ belongs_to_hour: id, empty: true }];
        }
        _.each(this.data, function (chunk) {
          keyedData[chunk[0].machine_id_real || chunk[0].machine_id] = chunk;
        });
        return keyedData;
      }
      return [];
    },
  },
  watch: {
    machineString() {
      this.load();
    },
    mode() {
      console.warn("MODE");
      if (this.mode === "active") {
        this.load();
      }
    },
  },
  components: {
    TimeHistory,
    TimeHistoryWrap,
    Configurator,
  },
};
</script>
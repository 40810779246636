<template>
  <div
    class="dashboardordersummary "
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-production-goal"
      :options="configOptions"
      :config="config"
    />
    <div
      class="left-aligned left-align align-left dashboard-widget"
      v-if="mode !== 'configuring'"
    >
      <order-summary-shared
        :machine_id="config.machineId  || machines[0].object_no"
        :period="config.period"
      ></order-summary-shared>
    </div>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import OrderSummaryShared from "@/components/shared/OrderSummary.vue";
import axios from "@/requests/axios";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import { execute_json } from "@/helpers/requests";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,

      pulse: null,

      configOptions: {
        ...stylingConfiguration,
        ...alignConfiguration,
        machineId: {
          type: "list",
          source: "machineList",
        },
        period: {
          type: "list",
          options: [
            "shift", //
            "24h", //
            "today", //
            "calendar", //
          ],
          default: "shift",
        },
      },
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "goals/loadProductionGoals",
        interval: 60,
      },
      {
        action: "machines/loadMachine",
        interval: 60,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine_id },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
      specificGoals: "goals/specificGoals",
    }),
    machine_id() {
      return this.config.machineId || this.machine.machine_id;
    },
    goals() {
      return this.specificGoals(
        this.config.location,
        this.currentMachineValues,
        this.config.target,
        this.config.production_type || null
      );
    },
    goal() {
      return this.goals[this.config.showIndex] || this.goals[0] || null;
    },
    machine() {
      return this.config.machineId
        ? this.machineById(this.config.machineId)
        : this.machines[0] || {};
    },

    currentDrawingValue() {
      return [
        {
          color: "green",
          per:
            (this.currentResultValue -
              (this.currentDiffValue > 0 ? this.currentDiffValue : 0)) /
            this.currentTargetValue,
        },
        {
          color: this.currentDiffValue < 0 ? "red" : "lightgreen",
          per: Math.abs(this.currentDiffValue / this.currentTargetValue),
        },
        {
          color: "gray",
          per: 1,
        },
      ];
    },
    currentResultValue() {
      return this.goal?.[this.targetMap[this.config.target]] || 0;
    },
    currentDiffValue() {
      return this.goal?.[this.diffMap[this.config.target]] || 0;
    },
    currentTargetValue() {
      return this.goal?.[this.config.target];
    },
    currentMachineValues() {
      return {
        machine_id: this.machine_id,
        category_id: this.machine.category,
        zone_id: this.machine.zone,
        department_id: this.machine.department,
      };
    },
    currentMachineLabels() {
      return {
        machine_id: this.machine.object_description,
        category_id: this.machine.cat_desc,
        zone_id: this.machine.zone_desc,
        department_id: this.machine.department_description,
      };
    },
  },
  watch: {},
  components: {
    Configurator,
    OrderSummaryShared
  },
};
</script>

<style>
</style>
<template>
  <div>
    <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
      name="dashboard-child-prod-value"
    />
    <div class="zonewrap">
      <div style="background:blue;color:white;flex:0;">
      {{ dataRow[dataKey] }}
      </div>
      <div
        v-for="m in machinesInZone"
        :key="m.machine_id"
        class="full-lh"
        :class="{preview:mode== 'preview'}"
      >
            {{ m.machine_id }}:{{ m.object_description }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  alignConfiguration,
  stylingConfiguration,
  sourceConfiguration,
  sourceComputed,
  sourceState,
  sourceGetters,
  sourceWatchers,
} from "@/helpers/configurator";
import { Pulse } from "@/helpers/heartbeat";
import _ from "lodash";
import Configurator from "../Configurator";
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    dataKey: {
      required: true,
    },
    dataRow: {
      required: true,
    },
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
 data() {
    return {
      loaded: false,
      pulse: null,
      cid: _.uniqueId("db"),
      multiSource: true,
    };
  },
  mounted() {
    let dependant = [];
    if (this.config.child === undefined) {
      this.config.child = {};
    }
    if (this.config.machineId) {
      dependant.push({
        action: "orders/loadPlannedOrdersForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
      dependant.push({
        action: "orders/loadRunningOrderForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
    }
    if (this.sqlSource) {
      dependant.push({
        action: "queries/loadQueryData",
        interval: 20,
        parameters: {
          record_no: this.sqlSource,
          parameters: this.parameters,
          record_plus: this.sqlSource + ":" + this.config.machineId,
        },
        primaryKey: "record_plus",
      });
    }
    this.pulse = new Pulse([
      {
        action: "machines/loadMachines",
        interval: 5,
      },
      ...dependant,
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  components: {
    Configurator,
  },
  computed: {
    ...mapState({
      ...sourceState,
    }),
    ...mapGetters({
      ...sourceGetters,
    }),
    ...sourceComputed,
    configOptions() {
      return {
        dataSource: {
            type: "list",
            source: "dataSources",
            default: "machines",
        },
        ...this.sqlQueryPlug,
        filtervalueKey: {
            type: "list",
            source: "sourceKeys",
        },
      };
    },
    machinesInZone() {
      return this.config.filtervalueKey ? this.source
        .filter((m) => m[this.config.filtervalueKey] == this.dataRow[this.dataKey])
        .sort((a, b) => {
          return a.machine_id > b.machine_id;
        }) : [];
    },
  },
  watch:{
    
    ...sourceWatchers,
  }
};
</script>

<style>
</style>
<template>
  <div
    class="statuswrap"
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-status"
      :options="configOptions"
      :config="config"
    />
    <div
      class="indicator dashboard"
      v-if="mode !== 'configuring'"
      @click.self="indicatorClick"
      :class="{
        [status]: true,
        [config.horizontalAlign]: config.horizontalAlign,
        [config.verticalAlign]: config.verticalAlign,
      }"
    >
      <!-- label if label is on -->
      <v-html
        class="indicator-machine_id"
        v-if="config.showMachineId || config.showMachineName"
        :tag="config.labelTag || 'h4'"
        :text="
          config.showMachineId && config.showMachineName
            ? machine.machine_id + ':' + machine.object_description
            : config.showMachineId
            ? machine.machine_id
            : machine.object_description
        "
      />
      <!-- stop -->
      <div
        v-if="displayMode === 'stop' && config.mode !== 'minimal'"
        class="flex-auto flex-column"
        @click="indicatorClick"
      >
        <v-html
          class="subheader"
          :tag="config.subheaderTag || 'h3'"
          :text="machine.stop_description"
        />
        <v-html
          class="align-middle text-xxl"
          :tag="config.textTag || 'p'"
          :text="machine.calendar_stop_time_duration_hh_mm"
        />
      </div>
      <!-- run -->
      <div
        class="flex-auto flex-column"
        @click="indicatorClick"
        v-if="displayMode === 'run' && config.mode !== 'minimal'"
      >
        <v-html
          class="subheader"
          :tag="config.subheaderTag || 'h3'"
          :text="$t('786')"
        />
        <v-html
          class="align-middle text-xxl"
          style="white-space: pre-wrap"
          :tag="config.textTag || 'p'"
          :text="[
            runtimePercentage + '%',
            '\n',
            runtime + '/' + runtimeStoptime,
          ]"
        />
        <!--
        <p v-else>{{time_running_hhmm}}</p>
        -->
      </div>
      <!-- prep -->
      <div
        class="flex-auto flex-column"
        @click="indicatorClick"
        v-if="displayMode === 'prep' && config.mode !== 'minimal'"
      >
        <v-html
          class="subheader"
          :tag="config.subheaderTag || 'h3'"
          :text="machine.stop_description"
        />
        <div
          class="warning"
          v-if="show_setup_warning && config.mode === 'runtime'"
          style="position: absolute; left: 50%; bottom: -10px;transform:translateX(-50%)"
        >
          <span class="fa-stack fa-4x" style="color: red">
            <i class="fas fa-siren-on fa-stack-1x fade-in-out"></i>
            <i class="fas fa-siren fa-stack-1x"></i> </span
          ><br />
        </div>
        <div class="row" v-if="config.mode == 'runtime'">
          <div class="col-12">
            <v-html
              class=""
              style="white-space: pre-wrap; z-index: 1"
              v-if="config.mode === 'runtime'"
              :tag="config.textTag || 'p'"
              :text="[$t('956') + ': ' + time_left_hhmm]"
            />
          </div>
          <div class="col-6">
            <v-html
              class=""
              style="white-space: pre-wrap; z-index: 1"
              v-if="config.mode === 'runtime'"
              :tag="config.textTag || 'p'"
              :text="[$t('4258'), '\n', time_planned_hhmm]"
            />
          </div>
          <div class="col-6">
            <v-html
              class=""
              style="white-space: pre-wrap; z-index: 1"
              v-if="config.mode === 'runtime'"
              :tag="config.textTag || 'p'"
              :text="[$t('600'), '\n', time_passed_hhmm]"
            />
          </div>
        </div>
      </div>
      <!-- action buttons -->
      <div
        class="button-wrap horizontal flex-auto"
        v-if="displayMode === 'prep' && config.mode == 'actionable'"
      >
        <button class="btn btn-success" href="#" @click.prevent="ControlDone">
          <span v-if="!pressedControl"> {{ $t("1281") }}</span>
          <loading v-else />
        </button>
        <a class="btn btn-danger" href="#" @click.prevent="Cancel">
          <span v-if="!pressedCancel">{{ $t("5014") }}</span>
          <loading v-else />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { Pulse } from "@/helpers/heartbeat";
import { mapState, mapGetters } from "vuex";
import { secondsToHhMm } from "@/helpers/dates";
import axios from "@/requests/axios";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import { execute_json } from "@/helpers/requests";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,

      pulse: null,
      pressedStop: false,
      pressedCancel: false,
      pressedControl: false,
      configOptions: {
        //...stylingConfiguration,
        ...alignConfiguration,
        machineId: {
          type: "list",
          source: "machineList",
        },
        mode: {
          type: "list",
          options: [
            "minimal", // just color indicator
            "informative", //more info
            "actionable", //more info + action buttons (first piece ok/cancel/etc)
            "runtime", //time based info if not a stall
          ],
          default: "informative",
        },
        indicatorClickBehavior: {
          type: "list",
          options: ["none", "setStopLink"],
          default: "setStopLink",
        },
        showMachineId: {
          type: "boolean",
          default: true,
        },
        showMachineName: {
          type: "boolean",
          default: false,
        },
        period: {
          type: "list",
          options: ["today", "shift"],
          default: "today",
        },
        labelTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit"],
          default: "h2",
        },
        subheaderTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit"],
          default: "h3",
        },
        textTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit"],
          default: "p",
        },
      },
    };
  },
  mounted() {
    this.pulse = new Pulse([
      {
        action: "orders/loadRunningOrderForMachine",
        interval: 300,
        primaryKey: "machine_id",
        parameters: { machine_id: this.machine.machine_id, reload: false },
      },
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    indicatorClick() {
      if (this.mode === "preview") {
        return false;
      }
      if (this.config.indicatorClickBehavior === "setStopLink") {
        this.$router.push(this.setStopLink);
        return true;
      }
      return false;
    },
    CancelForcedStop() {
      if (!this.pressedStop) {
        this.pressedStop = true;
        execute_json("cancel_forced_stop", {
          machine_id: this.machine.machine_id,
        })
          .then(() => {
            this.machine.force_stop_enabled = true;
            this.pressedStop = false;
          })
          .catch((err) => {
            console.log(err);
            this.pressedStop = false;
          });
      }
    },
    Cancel() {
      if (!this.pressedCancel) {
        this.pressedCancel = true;

        execute_json("resume_setup", {
          machine_id: this.machine.machine_id,
          set_status: "off",
          //sf326_stop_code:this.stop_reason
        })
          .then(() => {
            this.pressedCancel = false;
          })
          .catch((err) => {
            console.log(err);
            this.pressedCancel = false;
          });
        this.$router.push(this.setStopLink);
      }
    },
    ControlDone() {
      if (!this.pressedControl) {
        if (this.controlNeedsInput) {
          this.$router.push(
            "/interface/" + this.machine.machine_id + "/first-piece-ok"
          );
          return false;
        }
        this.pressedControl = true;
        execute_json("first_piece_ok", {
          machine_id: this.machine.machine_id,
        })
          .then(() => {
            this.pressedControl = false;
          })
          .catch((err) => {
            console.log(err);
            this.pressedControl = false;
          });
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      machineHasSf: "machines/machineHasSf",
      runningByMachine: "orders/runningByMachine",
    }),
    order() {
      return this.runningByMachine(this.machine.machine_id);
    },
    machine() {
      return this.config.machineId
        ? this.machineById(this.config.machineId)
        : this.machines[0] || {};
    },
    controlNeedsInput() {
      return (
        this.machineHasSf(this.machine_id, "23") ||
        this.machineHasSf(this.machine_id, "24") ||
        this.machineHasSf(this.machine_id, "29")
      );
    },
    setStopLink() {
      return (
        "/dashboards/" +
        (this.$route.params.dashboard || this.$route.params.template) +
        "/popup/" +
        this.machine.machine_id +
        "/stopcode"
      );
    },
    status() {
      let status = "";
      if (this.machine.status === "S") {
        if (this.machine.current_stop_reason === "-") {
          status += "status-uncoded";
        } else {
          status += "status-stop";
        }
      } else {
        status += "status-running";
      }
      status +=
        " c-" +
        (this.machine.stop_reason_color &&
        this.machine.stop_reason_color != "Automatic"
          ? this.machine.stop_reason_color.toLowerCase()
          : "") +
        " ";
      if (this.config.indicatorClickBehavior !== "none") {
        status += "clickable";
      }
      return status;
    },
    displayMode() {
      return this.machine.prepare_status && this.machine.status === "S"
        ? "prep"
        : this.machine.status === "S"
        ? "stop"
        : "run";
    },
    runtime() {
      let runtime = +this.machine.today_runtime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
      }
      return secondsToHhMm(runtime);
    },
    runtimeStoptime() {
      let runtime = +this.machine.today_runtime;
      let stoptime = +this.machine.today_calendar_stoptime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
        stoptime = +this.machine.current_shift_calendar_stop_time;
      }
      return secondsToHhMm(runtime + stoptime);
    },
    runtimePercentage() {
      let runtime = +this.machine.today_runtime;
      let stoptime = +this.machine.today_calendar_stoptime;
      if (this.config.period === "shift") {
        runtime = +this.machine.current_shift_runtime;
        stoptime = +this.machine.current_shift_calendar_stop_time;
      }
      return ((runtime * 100) / (runtime + stoptime)).toFixed(1);
    },
    time_left_s() {
      return this.time_planned_s - this.time_passed_s;
    },
    time_left_hhmm() {
      return secondsToHhMm(this.time_left_s + 29);
    },
    time_planned_s() {
      //value in h
      let planned_time =
        this.order && this.order[0] && this.order[0].planned_setup_time
          ? this.order[0].planned_setup_time
          : 0;
      return planned_time * 3600;
    },
    time_planned_hhmm() {
      return secondsToHhMm(this.time_planned_s);
    },
    time_passed_s() {
      return +this.machine.calendar_setup_time_duration || 0;
    },
    time_passed_hhmm() {
      return secondsToHhMm(this.time_passed_s);
    },
    time_running_s() {
      //value in h
      return +this.machine.runtime_after_stop_seconds || 0;
    },
    time_running_hhmm() {
      return secondsToHhMm(this.time_running_s);
    },
    show_setup_warning() {
      return this.time_left_s < 0 ? true : false;
    },
  },
  watch: {},
  components: {
    Configurator,
  },
};
</script>

<style>
</style>
<template>
  <div
    class="leanwrap"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-lean"
      :options="configOptions" 
      :config="config"
      @configuring="configuring"
    />
    <lean v-if="mode === 'preview'"
      :legendEnabled="config.showLegend"
      :centerSquare="!config.clearCenterSquare"
    ></lean>
    <lean v-if="mode === 'active'"
      :legendEnabled="config.showLegend"
      :centerSquare="!config.clearCenterSquare"
    ></lean>
  </div>
</template>

<script>
import Configurator from "./Configurator.vue";
import Lean from "@/components/Lean.vue";
import {
  stylingConfiguration,
  updateConfiguration,
} from "@/helpers/configurator";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: ["configuring"],
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {},
  methods: {
    configuring(config) {
      this.$emit("configuring", config);
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.config
        ? this.machineById(this.config.machineId)
        : { machine_id: this.config.machineId };
    },
    prevMachine() {
      return this.machines[0] ?? {};
    },
    currentMonth(){
      return new Date().getMonth() + 1;
    },
    configOptions(){
      let configOptions =  {
        ...stylingConfiguration,
        //...updateConfiguration,
        showLegend:{
          type:"boolean",
          default:false,
        },
        clearCenterSquare:{
          type:"boolean",
          default:false
        },
        machineId: {
          type: "list",
          source: "machineList",
        },
      };
      return configOptions;
    }
  },
  watch: {},
  components: {
    Configurator,
    Lean
  },
};
</script>

<style>
</style>
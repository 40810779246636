<template>
  <div
    class="graphwrap"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-graph"
      :options="configOptions"
      :config="config"
      @configuring="configuring"
    />
    <graph
      v-if="mode === 'preview'"
      :period="config.period || 'shift'"
      :machine="prevMachine"
      labelMode="hours"
      :task="config.task || 'stopreasons'"
      :axisColor="config.color"
      :axisLabelColor="config.color"
      :gridColor="config.gridColor"
      :type="config.type || 'bar'"
      :uses="config.uses"
      :font-size="config.fontSize"
    />
    <graph
      v-if="mode === 'active'"
      :period="config.period"
      :machine="machine"
      :labelMode="config.labelMode"
      :task="config.task"
      :type="config.type"
      :axisColor="config.color"
      :axisLabelColor="config.color"
      :gridColor="config.gridColor"
      :interval="config.interval"
      :animationDuration="config.animationDuration"
      :uses="config.uses"
      :font-size="config.fontSize"
    />
  </div>
</template>

<script>
import Graph from "@/components/Graph.vue";
import Configurator from "./Configurator.vue";
import {
  stylingConfiguration,
  updateConfiguration,
} from "@/helpers/configurator";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: ["configuring"],
  data() {
    return {
      loaded: false,
      configOptions: {
        ...stylingConfiguration,
        ...updateConfiguration,
        gridColor: {
          type: "color",
          default: "#eeeeee",
        },
        task: {
          type: "list",
          options: ["stopreasons", "availability","stopreasons_daily"],
          default: "stopreasons",
        },
        period: {
          type: "list",
          options: [
            {
              option: "shift",
              needs: { task: ["stopreasons", "availability"] },
            },
            {
              option: "today",
              needs: { task: ["stopreasons", "availability"] },
            },
            { option: "24h", needs: { task: ["stopreasons", "availability"] } },
            {
              option: "week",
              needs: { task: ["stopreasons", "availability"] },
            },
            {
              option: "lastweek",
              needs: { task: ["stopreasons", "availability"] },
            },
            { option: "30d", needs: { task: ["stopreasons", "availability","stopreasons_daily"] } },
          ],
          default: "shift",
        },
        machineId: {
          type: "list",
          source: "machineList",
        },
        labelMode: {
          type: "list",
          options: [
            {
              option: "hours",
              needs: { task: ["stopreasons","stopreasons_daily"] },
            },
            {
              option: "percentages",
              needs: { task: ["stopreasons"] },
            },
            {
              option: "perRaw",
              needs: { task: ["availability"] },
            },
          ],
        },
        type: {
          type: "list",
          options: [
            { option: "bar", needs: { task: ["stopreasons"] } },
            { option: "pie", needs: { task: ["stopreasons"] } },
            { option: "doughnut", needs: { task: ["stopreasons"] } },
            { option: "stackedBar", needs: { task: ["stopreasons_daily"] } },
            { option: "clampLine", needs: { task: ["availability"] } },
          ],
        },
        uses: {
          type: "list",
          options: [
            { option: "bar", needs: { task: ["stopreasons_daily"] } },
            { option: "pie", needs: { task: [] } },
            { option: "doughnut", needs: { task: [] } },
            { option: "stackedBar", needs: { task: [] } },
            { option: "clampLine", needs: { task: [] } },
          ],
        },
         fontSize: {
          type: "list",
          options: [
            12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60, 70, 80,
            90, 100, 110, 120,
          ],
          default: 12,
        },
      },
    };
  },
  mounted() {},
  methods: {
    configuring(config) {
      this.$emit("configuring", config);
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.config
        ? this.machineById(this.config.machineId)
        : { machine_id: this.config.machineId };
    },
    prevMachine() {
      return this.machines[0] ?? {};
    },
  },
  watch: {},
  components: {
    Graph,
    Configurator,
  },
};
</script>

<style>
</style>
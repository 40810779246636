<template>
  <div
    class="statuswrap"
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-gauge"
      :options="configOptions"
      :config="config"
    />

    <speed-gauge
      v-if="mode === 'preview'"
      :value="rawValue || mockValue"
      :limits="limits.length ? limits : mockLimits"
      :showTicks="config.showTicks != undefined ? config.showTicks : mockTicks"
      :min="min || 0"
      :max="max || 100"
      :innerTicks="config.innerTicks"
      :tickStroke="config.color"
      :needleColor="config.needleColor"
      :valueColor="config.valueColor"
      :dbColor="config.dbColor"
      :stepSize="stepSize"
      :labelPos="config.labelPos"
      :label="label"
      :outputClass="config.outputClass"
      :showValue="config.showValue"
      :needleWidth="config.needleWidth"
      :tickFontSize="10"
      :outputFontSize="20"
    />
    <speed-gauge
      v-if="mode === 'active'"
      :value="rawValue"
      :limits="limits"
      :showTicks="config.showTicks"
      :min="min"
      :max="max"
      :innerTicks="config.innerTicks"
      :tickStroke="config.color"
      :needleColor="config.needleColor"
      :valueColor="config.valueColor"
      :dbColor="config.dbColor" 
      :stepSize="stepSize"
      :labelPos="config.labelPos"
      :label="label"
      :outputClass="config.outputClass"
      :showValue="config.showValue"
      :needleWidth="config.needleWidth"
      :tickFontSize="10"
      :outputFontSize="20"
    />
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { mapGetters, mapState } from "vuex";
import SpeedGauge from "@/components/SpeedGauge.vue";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      mockValue: Math.floor(Math.random() * 100),
      mockLimits: [
        { color: "#ff0000", value: Math.floor(Math.random() * 20) + 70 },
        { color: "#ffff00", value: Math.floor(Math.random() * 30) + 50 },
        { color: "#00ff00", value: Math.floor(Math.random() * 50) },
      ],
      mockTicks: Math.floor(Math.random() * 20) > 10,
    };
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
       runningByMachine: "orders/runningByMachine",
      plannedByMachine: "orders/plannedByMachine",
    }),
    configOptions() {
      let configOptions = {
        ...stylingConfiguration,
        needleColor: {
          type: "color",
          default: "#000000",
        },
        needleWidth: {
          type: "numlist",
          options: ["5","10","15","20"],
          default:5
        },
        valueColor: {
          type: "color",
          default: "#ffffff",
        },
        dbColor: {
          type: "color",
          default: "#0f4534",
        },
        ...alignConfiguration,
        outputClass:{
          type: "list",
          options: ["small","big","minimal"],
        },
        labelPos: {
          type: "list",
          options: ["null","output","topleft","topright"],
        },
        label: {
          type: "text",

        },
        machineId: {
          type: "list",
          source: "machineList",
        },
        dataSource: {
          type: "list",
          source: "dataSources",
          default: "machines",
        },
        valueKey: {
          type: "list",
          source: "sourceKeys",
        },
        showValue:{
          type:"boolean",
          default:true
        },
         minKey: {
          type: "list",
          source: "machineKeys",
        },
        minValue: {
          type: "number",
          default: 0,
        },
        maxKey: {
          type: "list",
          source: "machineKeys",
        },
        maxValue: {
          type: "number",
          default: 100,
        },
        
        showTicks: {
          type: "boolean",
          default: true,
        },
        innerTicks: {
          type: "boolean",
          default: false,
        },
        stepSize:{
          type:"number",
          default:10,
        },
        numTicks:{
          type:"number",
          default:-1,
        },
        ...this.limitConfigOptions,
      };
      return configOptions;
    },
    limitConfigOptions() {
      let limitOptions = {
        numLimits: {
          type: "numlist",
          options: ["0", "1", "2", "3","4","5"],
          default: "0",
        },
      };
      for (let i = 0; i < this.config.numLimits; i++) {
        limitOptions["limit" + i + "Key"] = {
          type: "list",
          source: "machineKeys",
        };
        limitOptions["limit" + i + "Val"] = {
          type: "number",
          default: null,
        };
        limitOptions["limit" + i + "BackgroundColor"] = {
          type: "color",
          default: this.config.backgroundColor,
        };
      }
      return limitOptions;
    },
    machine() {
      return this.machineById(this.config.machineId);
    },
     runningOrders() {
      return this.runningByMachine(this.config.machineId);
    },
    plannedOrders() {
      return this.plannedByMachine(this.config.machineId);
    },
    sources() {
      return {
        plannedOrders: this.plannedOrders[0] || [],
        runningOrders: this.runningOrders[0] || [],
        machines: this.machine,
      };
    },
     source() {
      return this.config.dataSource
        ? this.sources[this.config.dataSource]
        : this.machine || this.machine;
    },
    rawValue() {
      return this.config.valueKey
        ? this.source[this.config.valueKey] || ""
        : this.config.valueKey;
    },
    limits() {
      let limits = [];
      for (let i = 0; i < this.config.numLimits; i++) {
        limits.push({
          color: this.config["limit" + i + "BackgroundColor"],
          value:
            (this.config["limit" + i + "Val"] || this.config["limit" + i + "Val"] === 0) &&
            this.config["limit" + i + "Val"] != -1
              ? this.config["limit" + i + "Val"]
              : this.machine[this.config["limit" + i + "Key"]],
        });
      }
      return limits;
    },
    max() {
      return this.config.maxValue && this.config.maxValue != -1
        ? this.config.maxValue
        : this.machine[this.config.maxKey];
    },
    min() {
      return this.config.minValue && this.config.minValue != -1
        ? this.config.minValue
        : this.machine[this.config.minKey];
    },
    stepSize(){
      if (this.config.numTicks > 0){
        return Math.floor((this.max - this.min) / this.config.numTicks);
      }
      return Math.max(this.config.stepSize,2);
    },
    label(){
      return this.config.label ? this.config.label.replace("[machine]",this.config.machineId) : null;
    }
  },
  watch: {},
  components: {
    Configurator,
    SpeedGauge,
  },
};
</script>

<style>
</style>
<template>
  <div
    class="valuewrap"
    :style="{
      ...valueColors,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
      name="production-value"
    />
    <v-html
      class="label"
      v-if="mode !== 'configuring' && config.label"
      :tag="config.labelTag || 'p'"
      :text="config.label"
      :color="valueColors.color"
    />
    <v-html
      v-if="mode === 'preview'"
      :tag="config.htmlTag || 'h2'"
      :text="formattedValue || rawValue"
      :color="valueColors.color"
    />
    <v-html
      v-if="mode === 'active' && config.htmlTag !== 'fit'"
      :tag="config.htmlTag"
      :text="formattedValue"
      :color="valueColors.color"
    />

    <svg
      v-if="mode === 'active' && config.htmlTag == 'fit'"
      class="svg-text-fit"
      viewBox="0 0 56 18"
    >
      <text :fill="config.color" x="50%" y="100%" text-anchor="middle">
        {{ formattedValue }}
      </text>
    </svg>
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { mapGetters, mapState } from "vuex";

import {
  alignConfiguration,
  stylingConfiguration,
  sourceConfiguration,
  sourceComputed,
  sourceState,
  sourceGetters,
  sourceWatchers,
} from "@/helpers/configurator";
import { hoursToHhMm, secondsToHhMm } from "@/helpers/dates";
import { Pulse } from "@/helpers/heartbeat";
import _ from "lodash";
import store from "@/store";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      timeConfigOptions: {
        valueUnits: {
          type: "list",
          options: ["seconds", "hours", "hours/100"],
          default: "seconds",
        },
        format: {
          type: "list",
          options: ["hh:mm"],
          default: "hh:mm",
        },
      },

      pulse: null,
      cid: _.uniqueId("db"),
    };
  },
  mounted() {
    let dependant = [];
    if (this.config.machineId) {
      dependant.push({
        action: "orders/loadPlannedOrdersForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
      dependant.push({
        action: "orders/loadRunningOrderForMachine",
        interval: 60,
        parameters: { machine_id: this.config.machineId },
        primaryKey: "machine_id",
      });
      if (this.sqlSource) {
        dependant.push({
          action: "queries/loadQueryData",
          interval: 20,
          parameters: {
            record_no: this.sqlSource,
            parameters: this.parameters,
            record_plus: this.sqlSource + ":" + this.config.machineId,
          },
          primaryKey: "record_plus",
        });
      }
    }

    this.pulse = new Pulse([
      {
        action: "machines/loadMachines",
        interval: 5,
      },
      ...dependant,
    ]);
  },
  beforeUnmount() {
    this.pulse.stop();
  },
  methods: {
    timeValue() {
      let v = +this.rawValue;
      //convert to seconds;
      return this.config.valueUnits === "hours"
        ? hoursToHhMm(v)
        : this.config.valueUnits === "hours/100"
        ? secondsToHhMm(v * 36)
        : this.config.valueUnits === "minutes"
        ? secondsToHhMm(v * 60)
        : secondsToHhMm(v);
    },
    colorBoxColors() {
      let backgroundColor = this.config.backgroundColor;
      let color = this.config.color;
      let limits = [];
      for (let i = 0; i < this.config.numLimits; i++) {
        limits.push("limit" + i);
      }
      limits.some((lKey) => {
        let limit =
          +this.machine[this.config[lKey + "Key"]] ||
          this.config[lKey + "Value"] ||
          0;
        //console.warn("limit!", limit);
        if (this.config["limitRule"] === "over" && +this.rawValue >= limit) {
          backgroundColor = this.config[lKey + "BackgroundColor"];
          color = this.config[lKey + "Color"];
          return true;
        }

        if (this.config["limitRule"] === "under" && +this.rawValue < limit) {
          backgroundColor = this.config[lKey + "BackgroundColor"];
          color = this.config[lKey + "Color"];
          return true;
        }

        if (
          this.config["limitRule"] === "clamp" &&
          +this.rawValue > this.config.clampAround - limit &&
          (!this.config.clampTop ||
            +this.rawValue < this.config.clampAround + limit)
        ) {
          backgroundColor = this.config[lKey + "BackgroundColor"];
          color = this.config[lKey + "Color"];
          return true;
        }
      });
      return {
        color,
        backgroundColor,
      };
    },
  },
  computed: {
    configOptions() {
      let vm = this;
      let configOptions = {
        ...stylingConfiguration,
        ...alignConfiguration,
        htmlTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit"],
          default: "p",
        },
        ...sourceConfiguration(this.sqlQueryPlug),
        displayType: {
          type: "list",
          options: ["time", "colorBox", "num"],
        },
        label: {
          type: "text",
        },
        labelTag: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p", "fit"],
          default: "p",
        },
      };
      if (this.config.displayType === "time") {
        configOptions = {
          ...configOptions,
          ...this.timeConfigOptions,
        };
      }
      if (this.config.displayType === "colorBox") {
        configOptions = {
          ...configOptions,
          ...this.colorBoxConfigOptions,
        };
      }
      return configOptions;
    },
    ...sourceComputed,
    
    colorBoxConfigOptions() {
      let colorBoxOptions = {
        numLimits: {
          type: "numlist",
          options: ["1", "2", "3"],
          default: "1",
        },
        limitRule: {
          type: "list",
          options: ["over", "under", "clamp"],
          default: "over",
        },
      };
      if (this.config.limitRule === "clamp") {
        colorBoxOptions["clampAround"] = {
          type: "numlist",
          options: ["50", "100", "150"],
          default: 100,
        };

        colorBoxOptions["clampTop"] = {
          type: "boolean",
          default: true,
        };
      }
      for (let i = 0; i < this.config.numLimits; i++) {
        colorBoxOptions["limit" + i + "Key"] = {
          type: "list",
          source: "machineKeys",
        };
        colorBoxOptions["limit" + i + "Value"] = {
          type: "number",
          default: "-1",
        };
        colorBoxOptions["limit" + i + "BackgroundColor"] = {
          type: "color",
          default: this.config.backgroundColor,
        };
        colorBoxOptions["limit" + i + "Color"] = {
          type: "color",
          default: this.config.Color,
        };
      }
      return colorBoxOptions;
    },
    ...mapState({
      machines: (state) => state.machines.index,
     ...sourceState,
    }),
    ...mapGetters({
      ...sourceGetters
    }),
    formattedValue() {
      if (this.config.displayType === "time") {
        return this.timeValue();
      }
      return this.rawValue;
    },
    valueColors() {
      if (this.config.displayType === "colorBox") {
        return this.colorBoxColors();
      }
      return {
        backgroundColor: this.config.backgroundEnabled
          ? this.config.backgroundColor
          : "transparent",
        color: this.config.color,
      };
    },
  },
  watch: {
    $route() {
      this.pulse.stop();
      let dependant = [];
      if (this.config.machineId) {
        dependant.push({
          action: "orders/loadPlannedOrdersForMachine",
          interval: 60,
          parameters: { machine_id: this.config.machineId },
          primaryKey: "machine_id",
        });
        dependant.push({
          action: "orders/loadRunningOrderForMachine",
          interval: 60,
          parameters: { machine_id: this.config.machineId },
          primaryKey: "machine_id",
        });
      }
      if (this.sqlSource) {
        store.dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: this.parameters,
        });
      }
      this.pulse = new Pulse([
        {
          action: "machines/loadMachines",
          interval: 5,
        },
        ...dependant,
      ]);
    },
   ...sourceWatchers
  },
  components: {
    Configurator,
  },
};
</script>

<style>
</style>
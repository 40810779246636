<template>
  <div
    class="block"
    :class="{future:isFutureDate,['status-'+status]:true}"
    v-if="validDate"
    :style="{
      gridArea: 'block-' + i,
    }"
  >
    <svg class="svg-text-fit" viewBox="0 0 40 18">
      <text
        x="50%"
        y="100%"
        text-anchor="middle"
        line-height="100%"
        stroke-width="0.5"
      >
        {{ i }}
      </text>
    </svg>
  </div>
  <div class="block future" v-if="!validDate"
  :style="{
      gridArea: 'block-' + i,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    month: {
      required: true,
    },
    i: {
      required: true,
    },
    status:{
      default:null
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  
  },
  beforeUnmount() {
  },
  methods: {
   
  },
  computed: {
    daysInMonth() {
      return new Date(new Date().getYear(), this.month, 0).getDate();
    },
    validDate(){
      return this.i <= this.daysInMonth;
    },
    currentMonth(){
      return new Date().getMonth() + 1;
    },
    currentDay(){
      return new Date().getDate();
    },
    isFutureDate(){
      return this.currentMonth < this.month || (this.currentMonth === this.month && this.currentDay < this.i)
    }
  },
};
</script>

<style lang="scss">
</style>
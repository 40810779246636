<template>
  <div
    class="graphwrap"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-sql-graph"
      :options="configOptions"
      :config="config"
      @configuring="configuring"
    />
    <graph
      v-if="mode === 'preview' && data"
      :dataset="loadedData || data"
      :numericX="false"
      :yKeys="['y']"
      :acceptedRange="
        {
          begin: config.beginRange,
          end: config.endRange,
          min: config.minY,
          max: config.maxY,
        } || {}
      "
      :updateOnDataChange="true"
      :lineColor="config.color"
      :backgroundEnabled="config.backgroundEnabled"
      :backgroundColor="config.backgroundColor"
      :machine="prevMachine"
      :xUnit="config.xUnit"
      labelMode="hours"
      :task="config.task || 'stopreasons'"
      :gridColor="config.gridColor"
      :type="config.type || 'bar'"
      :uses="config.uses"
      :font-size="config.fontSize"
    />
    <graph
      v-if="mode === 'active' && loadedData"
      :dataset="data"
      :numericX="false"
      :yKeys="['y']"
      :acceptedRange="
        {
          begin: config.beginRange,
          end: config.endRange,
          min: config.minY,
          max: config.maxY,
        } || {}
      "
      :updateOnDataChange="true"
      :xUnit="config.xUnit"
      :lineColor="config.color"
      :backgroundEnabled="config.backgroundEnabled"
      :backgroundColor="config.backgroundColor"
      :machine="prevMachine"
      labelMode="hours"
      :task="config.task || 'stopreasons'"
      :axisColor="config.color"
      :axisLabelColor="config.color"
      :gridColor="config.gridColor"
      :type="config.type || 'bar'"
      :uses="config.uses"
      :font-size="config.fontSize"
    />
  </div>
</template>

<script>
import Graph from "@/components/SimplePlot.vue";
import Configurator from "./Configurator.vue";
import {
  stylingConfiguration,
  updateConfiguration,
} from "@/helpers/configurator";
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: ["configuring"],
  data() {
    return {
      loaded: false,
      loadingFor: null,
      interval: null,
      exampleData: [
        { x: 1, y: 1 },
        { x: 2, y: 3 },
        { x: 3, y: 2 },
      ],
      loadedData: null,
    };
  },
  mounted() {
    this.loadData();
    this.startInterval();
  },
  beforeUnmount(){
    clearInterval(this.interval);
  },
  methods: {
    configuring(config) {
      this.$emit("configuring", config);
    },
    loadData() {
      console.warn("loading data...");
      if (
        this.config.sqlSource &&
        this.loadingFor != this.config.sqlSource &&
        this.paramsSet
      ) {
        this.fetchData();
      } else {
        if (!this.loaded) {
          store.dispatch("queries/loadQueryList");
          this.loaded = true;
        }
        setTimeout(this.loadData, 500);
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (this.cInterval && this.cInterval > 5) {
        this.interval = setInterval(this.intervalFetch, this.cInterval * 1000);
      }
    },
    intervalFetch() {
      if (this.loadingFor === null && this.config.sqlSource && this.paramsSet) {
        this.fetchData();
      }
    },
    fetchData() {
      this.loadingFor = this.config.sqlSource;
      store
        .dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: this.parameters,
        })
        .then((data) => {
          this.loadedData = data;
        }).catch((err)=>{
          console.log(err);
        }).finally(()=>{
          this.loadingFor = null;
        })
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      sqlData: (state) => state.queries.data,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      queryByRecordNo: "queries/byRecordNo",
    }),
    machine() {
      return this.config
        ? this.machineById(this.config.machineId)
        : { machine_id: this.config.machineId };
    },
    prevMachine() {
      return this.machines[0] ?? {};
    },
    sqlSource() {
      return this.config.sqlSource;
    },
    data() {
      return this.loadedData ? this.loadedData : this.exampleData;
    },
    activeQuery() {
      if (this.config.sqlSource) {
        return this.queryByRecordNo(this.config.sqlSource);
      }
      return null;
    },
    cInterval() {
      return this.config.interval;
    },
    sqlParametersFromString() {
      let sqlParameters = [];
      if (this.activeQuery) {
        const regex = /(<%.*?%>)/g;
        sqlParameters = this.activeQuery.sql_query
          .match(regex)
          .filter((m) => m !== "<%company_id%>");
      }
      return sqlParameters;
    },
    parameterConfigOptions() {
      let extras = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        extras["param_" + i] = {
          label: this.sqlParametersFromString[i],
          type: "string",
        };
      }
      return extras;
    },
    parameters() {
      let params = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        params[this.sqlParametersFromString[i]] = this.config["param_" + i];
      }
      return params;
    },
    paramsSet() {
      if (!this.activeQuery) {
        return false;
      }
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        if (
          this.config["param_" + i] == null ||
          this.config["param_" + i] == ""
        ) {
          return false;
        }
      }
      return true;
    },
    rangeOptions() {
      if (this.config.displayAcceptedRange) {
        return {
          beginRange: {
            type: "number",
          },
          endRange: {
            type: "number",
          },
        };
      }
      return {};
    },
    configOptions() {
      return {
        ...stylingConfiguration,
        ...updateConfiguration,
        gridColor: {
          type: "color",
          default: "#eeeeee",
        },
        sqlSource: {
          type: "list",
          source: "sqlList",
        },
        ...this.parameterConfigOptions,
        xUnit: {
          label: "x-axis units",
          type: "list",
          options: [
            "minute",
            "hour",
            "day",
            "week",
            "month",
            "quarter",
            "year",
          ],
        },
        displayAcceptedRange: {
          type: "boolean",
          default: false,
        },
        ...this.rangeOptions,

        minY: {
          type: "number",
        },
        maxY: {
          type: "number",
        },
         fontSize: {
          type: "list",
          options: [
            12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60, 70, 80,
            90, 100, 110, 120,
          ],
          default: 12,
        },
      };
    },
  },
  watch: {
    sqlSource() {
      this.loadData();
    },
    cInterval() {
      this.startInterval();
    },
  },
  components: {
    Graph,
    Configurator,
  },
};
</script>

<style>
</style>
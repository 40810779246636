<template>
  <div>
     <configurator
      v-if="mode === 'configuring'"
      :options="configOptions"
      :config="config"
      name="dashboard-child-prod-value"
    />
    {{ dataKey }}:{{dataRow.renderType == 'header' ? dataRow.group : dataRow[dataKey]}}
  </div>
</template>

<script>
import Configurator from "../Configurator";
export default {
  props: {
    dataKey: {
      required: true,
    },
    dataRow: {
      required: true,
    },
    mode:{
      default: "active", // "configuring", "previewing"
    },
    config:{

    }
  },
  data(){
    return {};
  },
  components:{
    Configurator
  },
  computed:{
    configOptions(){
      return {
        test:{
          type:"string"
        }
      }
    }
  }
};
</script>

<style>
</style>
<template>
  <div
    class="graphwrap"
    :style="{
      backgroundColor: config.backgroundEnabled
        ? config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-graph-location"
      :options="configOptions"
      :config="config"
      @configuring="configuring"
    />
    <div v-if="mode === 'preview'">
      <h2>No preview available during configuration</h2>
    </div>
    <graph
      v-if="mode === 'active'"
      source="location"
      :locationtype="config.locationtype || 'department'"
      :location="config.location"
      :machine="undefined"
      labelMode="hours"
      :period="config.period || '30d'"
      :task="config.task || 'stopreasons'"
      :axisColor="config.color"
      :axisLabelColor="config.color"
      :interval="config.interval"
      :gridColor="config.gridColor"
      :type="config.type || 'bar'"
      :uses="config.uses"
      :font-size="config.fontSize"
    />
  </div>
</template>

<script>
import Graph from "@/components/Graph.vue";
import Configurator from "./Configurator.vue";
import {
  stylingConfiguration,
  updateConfiguration,
} from "@/helpers/configurator";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: ["configuring"],
  data() {
    return {
      loaded: false,
      configOptions: {
        ...stylingConfiguration,
        ...updateConfiguration,
        gridColor: {
          type: "color",
          default: "#eeeeee",
        },
        task: {
          type: "list",
          options: [
            "stopreasons_location",
            "availability_location",
            "stopreasons_daily_location",
            "machineanalysis",
          ],
          default: "stopreasons_location",
        },
        period: {
          type: "list",
          options: [
            {
              option: "shift",
              needs: {
                task: ["stopreasons_location", "availability_location"],
              },
            },
            {
              option: "today",
              needs: {
                task: ["stopreasons_location", "availability_location"],
              },
            },
            {
              option: "24h",
              needs: {
                task: [
                  "stopreasons_location",
                  "availability_location",
                  "machineanalysis",
                ],
              },
            },
            {
              option: "week",
              needs: {
                task: ["stopreasons_location", "availability_location"],
              },
            },
            {
              option: "lastweek",
              needs: {
                task: ["stopreasons_location", "availability_location"],
              },
            },
            {
              option: "30d",
              needs: {
                task: [
                  "stopreasons_location",
                  "machineanalysis",
                  "availability_location",
                  "stopreasons_daily_location",
                ],
              },
            },
          ],
          required: true,
          default: "shift",
        },
        locationtype: {
          type: "list",
          required: true,
          source: "locationtypeList",
        },
        location: {
          type: "list",
          required: true,
          source: "locationList",
        },
        labelMode: {
          type: "list",
          options: [
            {
              option: "hours",
              needs: {
                task: ["stopreasons_location", "stopreasons_daily_location"],
              },
            },
            {
              option: "percentages",
              needs: { task: ["stopreasons_location"] },
            },
            {
              option: "perRaw",
              needs: { task: ["availability_location", "machineanalysis"] },
            },
          ],
          required: true,
        },
        type: {
          type: "list",
          options: [
            { option: "bar", needs: { task: ["stopreasons_location"] } },
            { option: "pie", needs: { task: ["stopreasons_location"] } },
            { option: "doughnut", needs: { task: ["stopreasons_location"] } },
            {
              option: "stackedBar",
              needs: { task: ["stopreasons_daily_location"] },
            },
            {
              option: "filledStackedBar",
              needs: { task: ["machineanalysis"] },
            },
            { option: "line", needs: { task: ["availability_location"] } },
          ],
          required: true,
        },
        uses: {
          type: "list",
          options: [
            {
              option: "bar",
              needs: {
                task: ["stopreasons_daily_location", "machineanalysis"],
              },
            },
            { option: "pie", needs: { task: [] } },
            { option: "doughnut", needs: { task: [] } },
            { option: "stackedBar", needs: { task: [] } },
            { option: "clampLine", needs: { task: [] } },
          ],
        },
        fontSize: {
          type: "list",
          options: [
            12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60, 70, 80, 90, 100,
            110, 120,
          ],
          default: 12,
        },
      },
      error: null,
      errorMessage: null,
    };
  },
  mounted() {},
  errorCaptured(err, comp, info) {
    this.error = err;
    this.errorMessage = info;
    console.warn("CAUGHT AN ERROR:", err, comp, info);
    return false;
  },
  methods: {
    configuring(config) {
      this.$emit("configuring", config);
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({ machineById: "machines/byId" }),
    machine() {
      return this.config
        ? this.machineById(this.config.machineId)
        : { machine_id: this.config.machineId };
    },
    prevMachine() {
      return this.machines[0] ?? {};
    },
    hasAllRequired() {
      let check = true;
      _.forEach(this.configOptions, (c, key) => {
        console.warn(c, key, this.config[key]);
        if (
          c.required &&
          (this.config[key] === null || this.config[key] == "")
        ) {
          check = false;
        }
      });
      return check;
    },
  },
  watch: {},
  components: {
    Graph,
    Configurator,
  },
};
</script>

<style>
</style>
<template>
  <div
    class="statuswrap"
    :style="{
      backgroundColor: this.config.backgroundEnabled
        ? this.config.backgroundColor
        : 'transparent',
      color: config.color,
    }"
    :class="{
      [config.horizontalAlign]: config.horizontalAlign,
      [config.verticalAlign]: config.verticalAlign,
    }"
  >
    <configurator
      v-if="mode === 'configuring'"
      name="dashboard-gauge"
      :options="configOptions"
      :config="config"
    />

    <speed-gauge
      v-if="mode === 'preview'"
      :value="rawValue || mockValue"
      :limits="limits.length ? limits : mockLimits"
      :showTicks="config.showTicks != undefined ? config.showTicks : mockTicks"
      :min="min || 0"
      :max="max || 100"
      :innerTicks="config.innerTicks"
      :tickStroke="config.color"
      :needleColor="config.needleColor"
      :valueColor="config.valueColor"
      :dbColor="config.dbColor"
      :stepSize="stepSize"
      :labelPos="config.labelPos"
      :label="label"
      :outputClass="config.outputClass"
      :showValue="config.showValue"
      :needleWidth="config.needleWidth"
      :tickFontSize="10"
      :outputFontSize="20"
    />
    <speed-gauge
      v-if="mode === 'active'"
      :value="rawValue"
      :limits="limits"
      :showTicks="config.showTicks"
      :min="min"
      :max="max"
      :innerTicks="config.innerTicks"
      :tickStroke="config.color"
      :needleColor="config.needleColor"
      :valueColor="config.valueColor"
      :dbColor="config.dbColor"
      :stepSize="stepSize"
      :labelPos="config.labelPos"
      :label="label"
      :outputClass="config.outputClass"
      :showValue="config.showValue"
      :needleWidth="config.needleWidth"
      :tickFontSize="10"
      :outputFontSize="20"
    />
  </div>
</template>

<script>
import Configurator from "./Configurator";
import { mapGetters, mapState } from "vuex";
import SpeedGauge from "@/components/SpeedGauge.vue";
import store from "@/store";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";

export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
  },
  emits: [],
  data() {
    return {
      loaded: false,
      mockValue: Math.floor(Math.random() * 100),
      mockLimits: [
        { color: "#ff0000", value: Math.floor(Math.random() * 20) + 70 },
        { color: "#ffff00", value: Math.floor(Math.random() * 30) + 50 },
        { color: "#00ff00", value: Math.floor(Math.random() * 50) },
      ],
      mockTicks: Math.floor(Math.random() * 20) > 10,
      loadingFor: null,
      interval: null,
      exampleData: { x: 1, y: 1 },
      loadedData: null,
    };
  },
  mounted() {
    this.loadData();
    this.startInterval();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    loadData() {
      console.warn("loading data...");
      if (
        this.config.sqlSource &&
        this.loadingFor != this.config.sqlSource &&
        this.paramsSet
      ) {
        this.fetchData();
      } else {
        if (!this.loaded) {
          store.dispatch("queries/loadQueryList");
          this.loaded = true;
        }
        setTimeout(this.loadData, 500);
      }
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (this.cInterval && this.cInterval > 5) {
        console.warn("enabling interval fetch for sql gauge");
        setInterval(this.intervalFetch, this.cInterval * 1000);
      }
    },
    intervalFetch() {
      if (this.loadingFor === null && this.config.sqlSource && this.paramsSet) {
        this.fetchData();
      }
    },
    fetchData() {
      this.loadingFor = this.config.sqlSource;
      store
        .dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: this.parameters,
        })
        .then((data) => {
          this.loadingFor = null;
          this.loadedData = data;
        });
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      runningByMachine: "orders/runningByMachine",
      plannedByMachine: "orders/plannedByMachine",
      queryByRecordNo: "queries/byRecordNo",
    }),
    configOptions() {
      let configOptions = {
        ...stylingConfiguration,
        needleColor: {
          type: "color",
          default: "#000000",
        },
        needleWidth: {
          type: "numlist",
          options: ["5", "10", "15", "20"],
          default: 5,
        },
        valueColor: {
          type: "color",
          default: "#ffffff",
        },
        dbColor: {
          type: "color",
          default: "#0f4534",
        },
        ...alignConfiguration,
        outputClass: {
          type: "list",
          options: ["small", "big", "minimal"],
        },
        labelPos: {
          type: "list",
          options: ["null", "output", "topleft", "topright"],
        },
        label: {
          type: "text",
        },
        machineId: {
          type: "list",
          source: "machineList",
        },
        sqlSource: {
          type: "list",
          source: "sqlList",
        },
        interval: {
          type: "list",
          options: [20, 60, 120],
        },
        ...this.parameterConfigOptions,
        valueKey: {
          type: "list",
          source: "sqlKeys",
        },
        showValue: {
          type: "boolean",
          default: true,
        },
        minKey: {
          type: "list",
          source: "sqlKeys",
        },
        minValue: {
          type: "number",
          default: 0,
        },
        maxKey: {
          type: "list",
          source: "sqlKeys",
        },
        maxValue: {
          type: "number",
          default: 100,
        },

        showTicks: {
          type: "boolean",
          default: true,
        },
        innerTicks: {
          type: "boolean",
          default: false,
        },
        stepSize: {
          type: "number",
          default: 10,
        },
        numTicks: {
          type: "number",
          default: -1,
        },
        ...this.limitConfigOptions,
      };
      return configOptions;
    },
    limitConfigOptions() {
      let limitOptions = {
        numLimits: {
          type: "numlist",
          options: ["0", "1", "2", "3", "4", "5"],
          default: "0",
        },
      };
      for (let i = 0; i < this.config.numLimits; i++) {
        limitOptions["limit" + i + "Key"] = {
          type: "list",
          source: "sqlKeys",
        };
        limitOptions["limit" + i + "Val"] = {
          type: "number",
          default: null,
        };
        limitOptions["limit" + i + "BackgroundColor"] = {
          type: "color",
          default: this.config.backgroundColor,
        };
      }
      return limitOptions;
    },
    machine() {
      return this.machineById(this.config.machineId);
    },
    runningOrders() {
      return this.runningByMachine(this.config.machineId);
    },
    plannedOrders() {
      return this.plannedByMachine(this.config.machineId);
    },
    sources() {
      return {
        plannedOrders: this.plannedOrders[0] || [],
        runningOrders: this.runningOrders[0] || [],
        machines: this.machine,
      };
    },
    sqlSource() {
      return this.config.sqlSource;
    },
    data() {
      return this.loadedData ? this.loadedData[0] : this.exampleData;
    },
    activeQuery() {
      if (this.config.sqlSource) {
        return this.queryByRecordNo(this.config.sqlSource);
      }
      return null;
    },
    cInterval() {
      return this.config.interval;
    },
    sqlParametersFromString() {
      let sqlParameters = [];
      if (this.activeQuery) {
        const regex = /(<%.*?%>)/g;
        sqlParameters = this.activeQuery.sql_query
          .match(regex)
          .filter((m) => m !== "<%company_id%>");
      }
      return sqlParameters;
    },
    parameterConfigOptions() {
      let extras = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        extras["param_" + i] = {
          label: this.sqlParametersFromString[i],
          type: "string",
        };
      }
      return extras;
    },
    parameters() {
      let params = {};
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        params[this.sqlParametersFromString[i]] = this.config["param_" + i];
      }
      return params;
    },
    paramsSet() {
      if (!this.activeQuery) {
        return false;
      }
      for (let i = 0; i < this.sqlParametersFromString.length; i++) {
        if (
          this.config["param_" + i] == null ||
          this.config["param_" + i] == ""
        ) {
          return false;
        }
      }
      return true;
    },
    rawValue() {
      return this.config.valueKey
        ? this.data[this.config.valueKey] || ""
        : this.config.valueKey;
    },
    limits() {
      let limits = [];
      for (let i = 0; i < this.config.numLimits; i++) {
        limits.push({
          color: this.config["limit" + i + "BackgroundColor"],
          value:
            (this.config["limit" + i + "Val"] ||
              this.config["limit" + i + "Val"] === 0) &&
            this.config["limit" + i + "Val"] != -1
              ? this.config["limit" + i + "Val"]
              : this.data[this.config["limit" + i + "Key"]],
        });
      }
      return limits;
    },
    max() {
      return this.config.maxValue && this.config.maxValue != -1
        ? this.config.maxValue
        : this.data[this.config.maxKey];
    },
    min() {
      return this.config.minValue && this.config.minValue != -1
        ? this.config.minValue
        : this.data[this.config.minKey];
    },
    stepSize() {
      if (this.config.numTicks > 0) {
        return Math.floor((this.max - this.min) / this.config.numTicks);
      }
      return Math.max(this.config.stepSize, 2);
    },
    label() {
      return this.config.label
        ? this.config.label.replace("[machine]", this.config.machineId)
        : null;
    },
  },
  watch: {
    sqlSource() {
      this.loadData();
    },
    cInterval() {
      this.startInterval();
    },
  },
  components: {
    Configurator,
    SpeedGauge,
  },
};
</script>

<style>
</style>
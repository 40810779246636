<template>
  <div class="svg-gauge" style="display: flex">
    <div
      class="speed-gauge-wrap"
      style="flex: 0 0 auto; display: block"
      :style="{
        width: this.w + 'px',
        height: this.h / 2 + 'px',
      }"
    >
      <svg
        class="typeRange"
        view-box="0 0 100 100"
        style="width: 100%; height: 100%"
      >
        <g class="scale" stroke="red">
          <line
            v-for="(scale, index) in scales"
            :key="index"
            :x1="scale.x1"
            :y1="scale.y1"
            :x2="scale.x2"
            :y2="scale.y2"
            stroke-width="3"
            :stroke="tickStroke"
          ></line>
          <text
            v-for="(tick, index) in ticks"
            :key="index"
            :x="tick.x"
            :y="tick.y"
            :stroke="tickStroke"
            :fill="tickStroke"
            stroke-width="0.6"
            :style="{
              fontSize: tickFontSize,
            }"
          >
            {{ tick.text }}
          </text>
        </g>
        <path class="outline" :d="outlineAttr" fill="#dddddd" />
        <path
          class="outline"
          v-for="(limit, index) in limitFills"
          :key="index"
          :d="limit.d"
          :fill="limit.c"
          :stroke="tickStroke"
          stroke-width="3"
          shape-rendering="optimizeQuality"
        />

        <polygon
          class="needle"
          :points="needle"
          :fill="needleColor"
          v-if="+value <= +max"
        />
      </svg>
      <div
        class="output"
        :class="outputClass"
        :style="{
          color: valueColor,
          fontSize: outputFontSize + 'px',
          backgroundColor: dbColor,
        }"
      >
        <p>
          {{ showValue ? value : "" }} {{ labelPos === "output" ? label : "" }}
        </p>
      </div>
      <p
        class="corner-output"
        v-if="labelPos === 'topleft' || labelPos === 'topright'"
        :class="labelPos"
        :style="{
          color: valueColor,
          fontSize: outputFontSize + 'px',
          backgroundColor: dbColor,
        }"
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>
<script>
const gaugeStrokeWidth = 15;
const triWidth = 0.6;
const largeArcLimit = 200 / 3;
const gaugeHalfStroke = gaugeStrokeWidth / 2;
const gaugeCenter = 50;
const piby200 = Math.PI / 200;
const gaugeRadius = gaugeCenter - gaugeHalfStroke;
const rad = Math.PI / 180;

export default {
  props: {
    value: {
      required: true,
    },
    limits: Array,
    limitStyle: {
      default: "linear",
    },
    min: {
      default: 0,
    },
    max: {
      default: 100,
    },
    stepSize: {
      default: 10,
    },
    showTicks: {
      default: false,
    },
    innerTicks: {
      default: false,
    },
    tickStroke: {
      default: "#000000",
    },
    needleColor: {
      default: "#000000",
    },
    valueColor: {
      default: "#ffffff",
    },
    dbColor: {
      default: "#0f4534",
    },
    labelPos: {
      default: null,
    },
    label: {
      default: null,
    },
    outputClass: {
      default: "small",
    },
    outputFontSize: {
      default: "30",
    },
    showValue: {
      default: true,
    },
    tickFontSize: {
      default: "10",
    },
    needleWidth: {
      default: 5,
    },
  },
  data() {
    return {
      ivalue: this.value,
      animating: false,
      gaugeStrokeWidth: gaugeStrokeWidth,
      w: null,
      h: null,
      cy: 150,
    };
  },
  mounted() {
    this.setSize();
    window.addEventListener("resize", this.setSize);
  },
  methods: {
    setSize() {
      var svg = this.$el;
      let windowHeight = parseInt(
        window.getComputedStyle(svg, null).getPropertyValue("height")
      );
      let windowWidth = parseInt(
        window.getComputedStyle(svg, null).getPropertyValue("width")
      );
      this.w = windowWidth / windowHeight < 2 ? windowWidth : windowHeight * 2;
      this.cy = Math.max(
        this.w / 2,
        parseInt(
          window.getComputedStyle(svg, null).getPropertyValue("height")
        ) /
          2 +
          this.offset
      );
      this.h = this.cy * 2;
    },

    getD1(cx, cy, r1, offset, delta) {
      var x1 = cx + r1,
        y1 = cy;
      var x2 = offset,
        y2 = cy;
      var r2 = r1 - delta;
      var x3 = x1 - delta,
        y3 = cy;
      var d1 =
        "M " +
        x1 +
        ", " +
        y1 +
        " A" +
        r1 +
        "," +
        r1 +
        " 0 0 0 " +
        x2 +
        "," +
        y2 +
        " H" +
        (offset + delta) +
        " A" +
        r2 +
        "," +
        r2 +
        " 0 0 1 " +
        x3 +
        "," +
        y3 +
        " z";
      return d1;
    },
    getD2(cx, cy, r1, offset, delta, a1, a2) {
      a2 *= rad;
      let r2 = r1 - delta;
      //let x2 = cx + r1 * Math.cos(a1);
      //let y2 = cy + r1 * Math.sin(a1);

      let x2 = offset,
        y2 = cy;
      let x3 = cx + r2 * Math.cos(a1);
      let y3 = cy + r1 * Math.sin(a1);
      let x4 = cx + r1 * Math.cos(a2);
      let y4 = cy + r1 * Math.sin(a2);
      let x5 = cx + r2 * Math.cos(a2);
      let y5 = cy + r2 * Math.sin(a2);
      if ([y4, x4, y5, x5, y2, x2, r2].some((i) => isNaN(i))) {
        return null;
      }
      let d2 =
        "M " +
        x4 +
        ", " +
        y4 +
        " A" +
        r1 +
        "," +
        r1 +
        " 0 0 0 " +
        x2 +
        "," +
        y2 +
        " H" +
        (offset + delta) +
        " A" +
        r2 +
        "," +
        r2 +
        " 0 0 1 " +
        x5 +
        "," +
        y5 +
        " z";
      return d2;
    },
    drawNeedle(cx, cy, r1, a) {
      let nx1 = cx + this.needleWidth * Math.cos((a - 90) * rad);
      let ny1 = cy + this.needleWidth * Math.sin((a - 90) * rad);

      let nx2 = cx + (r1 + 15) * Math.cos(a * rad);
      let ny2 = cy + (r1 + 15) * Math.sin(a * rad);

      let nx3 = cx + this.needleWidth * Math.cos((a + 90) * rad);
      let ny3 = cy + this.needleWidth * Math.sin((a + 90) * rad);

      let points =
        nx1 + "," + ny1 + " " + nx2 + "," + ny2 + " " + nx3 + "," + ny3;
      return points;
    },
    valueToAngle(value) {
      let pa = ((value - this.min) * 180) / (this.max - this.min) - 180;
      let x = this.cx + this.r1 * Math.cos(pa * rad);
      let y = this.cy + this.r1 * Math.sin(pa * rad);
      let lx = this.cx - x;
      let ly = this.cy - y;

      return Math.atan2(ly, lx) / rad - 180;
    },
  },
  computed: {
    offset() {
      return this.showTicks && !this.innerTicks ? 40 : 10;
    },
    cx() {
      return this.w ? this.w / 2 : 0;
    },
    r1() {
      return this.w ? this.cx - this.offset : 0;
    },
    delta() {
      return this.w ? ~~(this.r1 / 4) : 0;
    },
    r2() {
      return this.w ? this.r1 - this.delta : 0;
    },
    outlineAttr() {
      return this.getD1(this.cx, this.cy, this.r1, this.offset, this.delta);
    },
    limitFills() {
      let limits = [];
      this.limits.forEach((limit) => {
        let d = this.getD2(
          this.cx,
          this.cy,
          this.r1,
          this.offset,
          this.delta,
          this.valueToAngle(limit.start),
          this.valueToAngle(Math.min(this.max, limit.value))
        );
        if (d !== null) {
          limits.push({
            d,
            c: limit.color,
          });
        }
      });
      return limits;
    },
    scales() {
      let scales = [];
      let sr1 = this.r1 + 5;
      let sr2 = this.r2 - 5;
      //srT = r1 + 20;
      //let n = 0;
      let steps = (this.max - this.min) / this.stepSize;
      for (let sa = -180; sa < 0; sa += 180 / steps) {
        let sx1 = this.cx + sr1 * Math.cos(sa * rad);
        let sy1 = this.cy + sr1 * Math.sin(sa * rad);
        let sx2 = this.cx + sr2 * Math.cos(sa * rad);
        let sy2 = this.cy + sr2 * Math.sin(sa * rad);

        scales.push({
          class: "scale",
          x1: sx1,
          y1: sy1,
          x2: sx2,
          y2: sy2,
        });
      }
      let sa = 0;
      let sx1 = this.cx + sr1 * Math.cos(sa * rad);
      let sy1 = this.cy + sr1 * Math.sin(sa * rad);
      let sx2 = this.cx + sr2 * Math.cos(sa * rad);
      let sy2 = this.cy + sr2 * Math.sin(sa * rad);

      scales.push({
        class: "scale",
        x1: sx1,
        y1: sy1,
        x2: sx2,
        y2: sy2,
      });
      return scales;
    },
    ticks() {
      let ticks = [];
      if (!this.showTicks) {
        return ticks;
      }
      let srT = this.innerTicks ? this.r2 - 20 : this.r1 + 15;
      let n = 0;
      let steps = (this.max - this.min) / this.stepSize;
      for (let sa = -180; sa < 1; sa += 180 / steps) {
        let sxT = this.cx + srT * Math.cos(sa * rad);
        let syT = this.cy + srT * Math.sin(sa * rad);

        ticks.push({
          class: "scale",
          text: n * this.stepSize + this.min,
          x: sxT,
          y: syT,
        });
        n++;
      }
      let sa = 0;
      let sxT = this.cx + srT * Math.cos(sa * rad);
      let syT = this.cy + srT * Math.sin(sa * rad);

      ticks.push({
        class: "scale",
        text: this.max,
        x: sxT,
        y: syT,
      });
      return ticks;
    },
    angle() {
      return this.valueToAngle(this.value);
    },
    needle() {
      return this.drawNeedle(this.cx, this.cy, this.r1, this.angle);
    },
  },
  watch: {
    value() {
      //TODO: update ivalue slowly
      this.ivalue = this.value;
    },
  },
};
</script>
<style lang="scss">
/*
    .gauge-arc-circle {
    filter: drop-shadow( 0px 5px 5px #000 );
    }*/
.svg-gauge {
  align-content: center;
  justify-content: center;
  position: relative;
  text {
    text-anchor: middle;
    dominant-baseline: alphabetic;
    font: 12px verdana, sans-serif;
    fill: #aaa;
  }
}
.output {
  line-height: 35px;
  width: 60px;
  height: 30px;
  background-color: #0f4534;
  border-radius: 60px 60px 0 0;
  position: absolute;
  color: white;
  bottom: 0px;
  left: 50%;
  text-align: center;
}
.speed-gauge-wrap {
  position: Relative;
  .output {
    line-height: 35px;
    width: 60px;
    height: 30px;
    background-color: #0f4534;
    border-radius: 60px 60px 0 0;
    position: absolute;
    color: white;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: block !important;
    text-align: center;
    &.big {
      width: 120px;
      height: 60px;
      line-height: 85px;
    }
  }

  .corner-output {
    position: absolute;
    top: 0;
    padding: 5px;
    border-radius: 5px;
    &.topleft {
      left: 0;
      border-bottom-right-radius: 15px;
    }
    &.topright {
      right: 0;
      border-bottom-left-radius: 15px;
    }
  }
}
</style>